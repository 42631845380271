import React from 'react'
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, CssBaseline, Divider, Drawer, IconButton, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../State/Authentication/Action';

const menu = [
  { title: "Orders", icon: <ShoppingBagIcon /> },
  // { title : "Favorites", icon:<FavoriteIcon/>},
  // { title : "Address", icon:<HomeIcon/>},
  // { title : "Payments", icon:<AccountBalanceWalletIcon/>},
  // { title : "Notifications", icon:<NotificationsActiveIcon/>},
  // { title : "Events", icon:<EventIcon/>},
  { title: "Logout", icon: <LogoutIcon /> }
]

const ProfileNavigation = () => {

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const drawerWidth = 240;

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNavigate = (item) => {
    if (item.title === "Logout") {
      dispatch(logout());
      navigate("/");
    } else if (item.title === "Favorites") {
      navigate('favorites')
    } else if (item.title === "Orders") {
      navigate('orders')
    }
  }
  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="relative"
          sx={{
            width: { sm: `0px` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            //   container={container}
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {<div className='w-[70vw] lg:w-[20vw] pt-20 flex flex-col justify-center text-xl space-y-[1.65rem]'>
              {menu.map((item, i) => <React.Fragment key={i}>
                <div onClick={() => handleNavigate(item)} className='px-5 flex items-center gap-5 cursor-pointer'>
                  {item.icon}
                  <span>
                    {item.title}
                  </span>
                </div>
                {i !== menu?.length - 1 && <Divider />}
              </React.Fragment>)}
            </div>}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, top: 80 },
            }}
            open
          >
            {<div className='w-[70vw] lg:w-[20vw] pt-20 flex flex-col justify-center text-xl space-y-[1.65rem]'>
              {menu.map((item, i) => <React.Fragment key={i}>
                <div onClick={() => handleNavigate(item)} className='px-5 flex items-center gap-5 cursor-pointer'>
                  {item.icon}
                  <span>
                    {item.title}
                  </span>
                </div>
                {i !== menu?.length - 1 && <Divider />}
              </React.Fragment>)}
            </div>}
          </Drawer>
        </Box>
      </Box>
    </div>
  )
}

export default ProfileNavigation