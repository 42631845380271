export const CREATE_RESTAURANT_REQUEST = 'CREATE_RESTAURANT_REQUEST';
export const CREATE_RESTAURANT_SUCCESS = 'CREATE_RESTAURANT_SUCCESS';
export const CREATE_RESTAURANT_FAILURE = 'CREATE_RESTAURANT_FAILURE';

export const GET_ALL_RESTAURANTS_REQUEST = 'GET_ALL_RESTAURANTS_REQUEST';
export const GET_ALL_RESTAURANTS_SUCCESS = 'GET_ALL_RESTAURANTS_SUCCESS';
export const GET_ALL_RESTAURANTS_FAILURE = 'GET_ALL_RESTAURANTS_FAILURE';

export const DELETE_RESTAURANT_REQUEST = 'DELETE_RESTAURANT_REQUEST';
export const DELETE_RESTAURANT_SUCCESS = 'DELETE_RESTAURANTS_SUCCESS';
export const DELETE_RESTAURANT_FAILURE = 'DELETE_RESTAURANTS_FAILURE';

export const UPDATE_RESTAURANT_REQUEST = 'UPDATE_RESTAURANT_REQUEST';
export const UPDATE_RESTAURANT_SUCCESS = 'UPDATE_RESTAURANTS_SUCCESS';
export const UPDATE_RESTAURANT_FAILURE = 'UPDATE_RESTAURANTS_FAILURE';

export const GET_RESTAURANT_BY_ID_REQUEST = 'GET_RESTAURANT_BY_ID_REQUEST';
export const GET_RESTAURANT_BY_ID_SUCCESS = 'GET_RESTAURANT_BY_ID_SUCCESS';
export const GET_RESTAURANT_BY_ID_FAILURE = 'GET_RESTAURANT_BY_ID_FAILURE';

export const GET_RESTAURANT_BY_USER_ID_REQUEST = 'GET_RESTAURANT_BY_USER_ID_REQUEST';
export const GET_RESTAURANT_BY_USER_ID_SUCCESS = 'GET_RESTAURANT_BY_USER_ID_SUCCESS';
export const GET_RESTAURANT_BY_USER_ID_FAILURE = 'GET_RESTAURANT_BY_USER_ID_FAILURE';

export const UPDATE_RESTAURANT_STATUS_REQUEST = 'UPDATE_RESTAURANT_STATUS_REQUEST';
export const UPDATE_RESTAURANT_STATUS_SUCCESS = 'UPDATE_RESTAURANT_STATUS_SUCCESS';
export const UPDATE_RESTAURANT_STATUS_FAILURE = 'UPDATE_RESTAURANT_STATUS_FAILURE';

export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';

export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE';

export const GET_ALL_EVENTS_REQUEST = 'GET_ALL_EVENTS_REQUEST';
export const GET_ALL_EVENTS_SUCCESS = 'GET_ALL_EVENTS_SUCCESS';
export const GET_ALL_EVENTS_FAILURE = 'GET_ALL_EVENTS_FAILURE';

export const GET_RESTAURANTS_EVENTS_REQUEST = 'GET_RESTAURANTS_EVENTS_REQUEST';
export const GET_RESTAURANTS_EVENTS_SUCCESS = 'GET_RESTAURANTS_EVENTS_SUCCESS';
export const GET_RESTAURANTS_EVENTS_FAILURE = 'GET_RESTAURANTS_EVENTS_FAILURE';

export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE';

export const DELETE_FOOD_CATEGORY_REQUEST = 'DELETE_FOOD_CATEGORY_REQUEST';
export const DELETE_FOOD_CATEGORY_SUCCESS = 'DELETE_FOOD_CATEGORY_SUCCESS';
export const DELETE_FOOD_CATEGORY_FAILURE = 'DELETE_FOOD_CATEGORY_FAILURE';

export const GET_RESTAURANTS_CATEGORY_REQUEST = 'GET_RESTAURANTS_CATEGORY_REQUEST';
export const GET_RESTAURANTS_CATEGORY_SUCCESS = 'GET_RESTAURANTS_CATEGORY_SUCCESS';
export const GET_RESTAURANTS_CATEGORY_FAILURE = 'GET_RESTAURANTS_CATEGORY_FAILURE';