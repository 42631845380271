import { Box, Button, Chip, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { uploadImageToCloudinary } from '../Util/UploadToCloudinary';
import { useDispatch, useSelector } from 'react-redux';
import { createMenuItem } from '../../../State/Menu/Action';
import { getIngredientsOfRestaurant } from '../../../State/Ingredients/Action';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';

const initialValues = {
  name: '',
  description: '',
  category: '',
  restaurantId: '',
  vegetarian: false,
  seasonal: false,
  // ingredients: [],
  pricesSizes: [],
  images: []
}

const CreateMenuForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jwt = localStorage.getItem("jwt");
  const { restaurant, ingredients, pricesSizes } = useSelector(store => store);
  const [uploadImage, setUploadImage] = useState(false);

  const validate = (values) => {
    console.log('validate');
    const errors = {};
    // if (!values.price || !values.price.match(/^\d{1,}(\.\d{0,4})?$/)) {
    //   errors.price = 'This field is missing or invalid';
    //   console.log('validate and price is not good');

    // }
    if (!values.name) {
      errors.name = "This field is required";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (values) => {
      const updateData = { ...values, restaurantId: restaurant?.restaurants[0]?.id }
      dispatch(createMenuItem({ menu: updateData, jwt }))
      console.log("formik values menu form", updateData);
      navigate('/admin/restaurants/menu')
    }
  });

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    setUploadImage(true);
    const image = await uploadImageToCloudinary(file);
    console.log(image);
    formik.setFieldValue("images", [...formik.values.images, image])
    setUploadImage(false);
  }

  const handleRemoveImage = (index) => {
    const updateImages = [...formik.values.images]
    updateImages.splice(index, 1);
    formik.setFieldValue("images", updateImages);
  }

  useEffect(() => {
    dispatch(getIngredientsOfRestaurant({ restaurantId: restaurant?.restaurants[0]?.id, jwt }));
    console.log('ingredients of restaurant are', ingredients);
  }, []);
  console.log('formik values is ------', formik.values);
  console.log('formik error is ------', formik.errors);
  return (
    <div className='py-10 lg:flex items-center justify-center min-h-screen'>
      <div className='lg:max-w-4xl'>
        <h1 className='font-bold text-2xl text-center py-2'>Add New Menu Item</h1>
        <form onSubmit={formik.handleSubmit} className='space-y-4'>
          <Grid container spacing={2}>
            <Grid item xs={12} className='flex flex-wrap gap-5'>
              <input type='file' accept='image/*' id='fileInput' style={{ display: "none" }} onChange={handleImageChange} />
              <label className='relative' htmlFor='fileInput'>
                <span className='w-24 h-24 cursor-pointer flex items-center justify-center p-3 border rounded-md border-gray-600'>
                  <AddPhotoAlternateIcon className='text-white'></AddPhotoAlternateIcon>
                </span>
                {uploadImage && <div className='absolute left-0 right-0 top-0 bottom-0 w-24 h-24 flex justify-center items-center'>
                  <CircularProgress />
                </div>}
              </label>
              <div className='flex flex-wrap gap-2'>
                {formik.values.images.map((image, index) => <div key={index} className='relative'>
                  <img
                    className='w-24 h-24 object-cover'
                    src={image}
                    alt=''
                    key={index}
                  />
                  <IconButton
                    size='small'
                    sx={{ position: 'absolute', top: 0, right: 0, outline: 'none' }}
                    onClick={() => handleRemoveImage(index)}>
                    <CloseIcon sx={{ fontSize: "1rem" }} />
                  </IconButton>
                </div>)}
              </div>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='name'
                name='name'
                label='Name'
                variant='outlined'
                onChange={formik.handleChange}
                step={0.5}
                value={formik.values.name}></TextField>{formik.errors.name ? <div>{formik.errors.name}</div> : null}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='description'
                name='description'
                label='Description'
                variant='outlined'
                onChange={formik.handleChange}
                value={formik.values.description}></TextField>
            </Grid>
            {/* <Grid item xs={12} lg={6}>
            <TextField 
                fullWidth 
                id='price' 
                name='price' 
                label='Price' 
                variant='outlined'
                onChange={formik.handleChange}
                value={formik.values.price}></TextField>{formik.errors.price ? <div>{formik.errors.price}</div> : null}
          </Grid> */}
            <Grid item xs={12} lg={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Food Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formik.values.category}
                  name="category"
                  label="Food Category"
                  onChange={formik.handleChange}
                >
                  {restaurant.categories.map((category) => <MenuItem key={category.id} value={category}>{category.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12}>
          <FormControl fullWidth>
        <InputLabel id="demo-multiple-chip-label">Ingredients</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          name="ingredients"
          multiple
          value={formik.values.ingredients}
          onChange={formik.handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Ingredients" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value.id} label={value.name} />
              ))}
            </Box>
          )}
        //   MenuProps={MenuProps}
        >
          {ingredients.ingredients.map((ingredient, index) => (
            <MenuItem
              key={ingredient.id}
              value={ingredient}
            >
              {ingredient.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
          </Grid> */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-chip-label">Price Size</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  name="pricesSizes"
                  multiple
                  value={formik.values.pricesSizes}
                  onChange={formik.handleChange}
                  input={<OutlinedInput id="select-multiple-chip" label="Price & Size" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value.id} label={value.size + '   ' + value.price} />
                      ))}
                    </Box>
                  )}
                //   MenuProps={MenuProps}
                >
                  {pricesSizes.pricesSizes.map((priceSize, index) => (
                    <MenuItem
                      key={priceSize.id}
                      value={priceSize}
                    >
                      {priceSize.size} - {priceSize.price}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Is Seasonal</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="seasonal"
                  value={formik.values.seasonal}
                  name="seasonal"
                  label="Is Seasonal"
                  onChange={formik.handleChange}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Is Vegetarian</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="vegetarian"
                  value={formik.values.vegetarian}
                  name="vegetarian"
                  label="Is Vegetarian"
                  onChange={formik.handleChange}
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>

          </Grid>
          <Button variant='contained' color='primary' type='submit'>Create Menu Item</Button>
        </form>
      </div>
      <ToastContainer />

    </div>
  )
}

export default CreateMenuForm