import React from 'react'
import { Avatar, Badge, IconButton } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { yellow } from '@mui/material/colors';
import "./Navbar.css";
import { Person } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';

// Importing the Halal Yum Logo From the Storage
import Logo from '../../Storage/logo.png';

export  const Navbar = () => {
  const {auth, cart, guestCart} = useSelector(store => store);
  const {restaurant} = useSelector(store => store);
  const navigate = useNavigate();
  const jwt = localStorage.getItem("jwt");

  const handleAvatarClick = () => {
    if (auth.user?.role === "ROLE_CUSTOMER" || auth.user?.role === 'ROLE_RESTAURANT_OWNER') {
      navigate("/my-profile/");
    } else {
      navigate("/")
    }
  }
  return (
    <div className='px-5  sticky top-0 z-50 py-[.8rem]  lg:px-20 flex justify-between font-logo-font glass-effect shadow-md'>


        <div className='lg:mr-10 cursor-pointer flex items-center space-x-4'>
          {/* The Halal Yum Logo Have been Added here */}
          <img onClick={() => navigate("/")} src={Logo} alt="Halal Yum Logo" className='w-[40px] sm:w-[48px]'/>

          <li onClick={() => navigate("/")} className='logo font-semibold text-primary text-[19px] sm:text-2xl'>
            HALAL YUM
          </li>

      </div>

        <div className='flex items-center gap-3'>
        

          <div className=''>
           { auth.user ? <Avatar onClick={handleAvatarClick} sx={{bgcolor:"#9D8C62", color:"#ffffff"}}>{auth.user?.fullName[0].toUpperCase()}</Avatar> : <IconButton onClick={() => navigate("/account/login")}><Person sx={{color:"#9D8C62"}}/></IconButton>}
          </div>

        {auth.user?.role === 'ROLE_RESTAURANT_OWNER' && <div>
          <IconButton onClick={() => navigate(`/admin/restaurants/${restaurant?.restaurants[0]?.id}`)}>
              <Badge color=''>
                <DashboardIcon sx={{fontSize:"1.5rem",  color: '#9D8C62', }}/>
              </Badge>
            </IconButton> 
            <IconButton onClick={() => navigate("/admin/restaurants/createrestaurant")}>
              <Badge color=''>
                <RestaurantMenuIcon sx={{fontSize:"1.5rem", color: '#9D8C62'}}/>
              </Badge>
            </IconButton>          
          </div>}

          {/* Color of the Cart icons have been updated below */}
          <div className=''>
            <IconButton onClick={() => navigate("/cart")}>
                <Badge color='' badgeContent={jwt ? cart?.cart?.items?.length : guestCart?.cartItems?.length}>
                  <ShoppingCartIcon sx={{fontSize:"1.5rem", color: '#9D8C62'}} />
                </Badge>
            </IconButton>          
          </div>
        </div>
        


    </div>
  )
}

