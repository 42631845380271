import { Accordion, AccordionDetails, AccordionSummary, Button, FormControlLabel, FormGroup, Radio, RadioGroup } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState } from 'react'
import Checkbox from '@mui/material/Checkbox';
import { categorizeIngredients } from '../util/categorizeIngredients';
import { useDispatch } from 'react-redux';
import { addItemToCart } from '../../State/Cart/Action';
import { useNavigate } from 'react-router-dom';
import { addItemToGuestCart } from '../../State/GuestCart/Action';

const MenuCard = ({ menuItem }) => {

  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [selectedPriceSize, setSelectedPriceSize] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {guestCart} = useState(store => store);
  
  const handleCheckBoxChange = (itemName) => {
    if (selectedIngredients.includes(itemName)) {
      setSelectedIngredients(selectedIngredients.filter((item) => item !== itemName))
    } else {
      setSelectedIngredients([...selectedIngredients, itemName])
    }
  }

  const handleRadioChangeForPriceSize = (event) => {
    console.log('event targer for radio price size is', event.target);
    setSelectedPriceSize(event.target.value);
  }

  

  const handleAddItemToCart = (event) => {
    event.preventDefault();
    const jwt = localStorage.getItem("jwt");
    if (!jwt) {
      const size = selectedPriceSize?.split('-')[0];
      const price = +Number(selectedPriceSize?.split('-')[1]);
      const cartItemGuest = {
        id: Math.floor(Math.random() * 100),
        foodId: menuItem.id,
        quantity: 1,
        size: size,
        totalPrice: price,
        price: price,
        food : {
          images: menuItem?.images,
          name: menuItem?.name
        }
      }
      // navigate("/account/login")
      dispatch(addItemToGuestCart(cartItemGuest));
    } else {
      const cartItem = {
        foodId: menuItem.id,
        quantity: 1,
        ingredients: selectedIngredients,
        size: selectedPriceSize.split('-')[0],
      }
      const requestData = {
        jwt: jwt,
        cartItem: cartItem
      }
      console.log('requested data is', requestData);
      dispatch(addItemToCart(requestData));
    }
  }


  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <div className='lg:flex items-center justify-between'>
          <div className='lg:flex items-center lg:gap-5'>
            <img
              className='w-[7rem] h-[7rem] object-cover'
              alt=''
              src={menuItem.images[0]}
            />
            <div className='space-y-1 lg:space-y-5 lg:max-w-2xl'>
              <p className='font-semibold text-xl'>{menuItem.name}</p>
              <p className='text-gray-400'>{menuItem.description}</p>
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <form onSubmit={handleAddItemToCart}>
          <div className='flex gap-5 flex-wrap'>
            {
              Object.keys(categorizeIngredients(menuItem.ingredients)).map((category, index) =>
                <div key={index}>
                  <p>{category}</p>
                  <FormGroup>
                    {categorizeIngredients(menuItem.ingredients)[category].map((item) =>
                      <FormControlLabel key={item.id} control={<Checkbox onChange={() => handleCheckBoxChange(item.name)} />} label={item.name} />
                    )}
                  </FormGroup>
                </div>
              )
            }
          </div>
          <div className='flex gap-5 flex-wrap'>
            {
              <div>
                <p>{menuItem?.pricesSizes[0]?.priceSizeCategory?.priceSizeCategoryName}</p>
                <RadioGroup value={selectedPriceSize} onChange={handleRadioChangeForPriceSize}>
                  {menuItem.pricesSizes.map((priceSize, index) =>
                    <FormControlLabel key={priceSize.id} value={priceSize.size +'-'+ priceSize.price} control={<Radio required={true} />} label={priceSize.size + ' $' + priceSize.price} />
                  )
                  }
                </RadioGroup>
              </div>
            }
          </div>
          <div className='pt-5'>
            <Button variant='contained' type='submit' disabled={menuItem.available ? false : true}>
              {menuItem.available ? "Add to Cart" : "Out of Stock"}
            </Button>
          </div>
        </form>
      </AccordionDetails>
    </Accordion>
  )
}

export default MenuCard