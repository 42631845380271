export const topMeals = [
    {
        id:1,
        image: "https://halalyumbucket.s3.ca-central-1.amazonaws.com/tehzeebtandooripizza.jpg",
        title:"Pizza"
    },
    {
        id:2,
        image: "https://halalyumbucket.s3.ca-central-1.amazonaws.com/cyanidewings.jpg",
        title:"Wings"
    },
    {
        id:3,
        image: "https://halalyumbucket.s3.ca-central-1.amazonaws.com/mangolassi.jpg",
        title:"Drinks"
    },
    {
        id:4,
        image: "https://halalyumbucket.s3.ca-central-1.amazonaws.com/fries.jpg",
        title:"Sides"
    },
    {
        id:5,
        image: "https://halalyumbucket.s3.ca-central-1.amazonaws.com/chay.jpg",
        title:"Chay"
    }
]