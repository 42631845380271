import { Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MenuCard from './MenuCard';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getRestaurantById, getRestaurantsCategory } from '../../State/Restaurant/Action';
import { getMenuItemByRestaurantIdAndFilter } from '../../State/Menu/Action';
import { ToastContainer } from 'react-toastify';
import { Navbar } from '../Navbar/Navbar';
const foodTypes = [
    { label: "ALL", value: "all" },
    { label: "Vegetarian only", value: "vegetarian" },
    { label: "Non-Vegetarian", value: "nonVegetarian" },
    { label: "Seasonal", value: "seasonal" }
]

function RestaurantDetails() {
    const dispatch = useDispatch();
    const jwt = localStorage.getItem("jwt");
    const { restaurant, menu } = useSelector(store => store);
    const [selectedCategory, setSelectedCategory] = useState("ALL");
    const [selectedFoodType, setSelectedFoodType] = useState("all");

    const { restaurantId } = useParams();

    const handleFilterFoodType = (e, value) => {
        setSelectedFoodType(value);
        console.log(e.target.value, e.target.name);
    }

    const handleFilterCategory = (e, value) => {
        setSelectedCategory(value);
        console.log(e.target.value, e.target.name);
    }

    console.log('restaurant ---- from details', restaurant);

    console.log('menu ---- from details', menu);

    useEffect(() => {
        dispatch(getRestaurantById({ jwt, restaurantId: restaurantId }));
        dispatch(getRestaurantsCategory({ jwt, restaurantId: restaurantId }));
    }, [jwt, restaurantId])

    useEffect(() => {
        dispatch(getMenuItemByRestaurantIdAndFilter({
            restaurantId: restaurantId,
            vegetarian: selectedFoodType === 'vegetarian',
            nonVegetarian: selectedFoodType === 'nonVegetarian',
            seasonal: selectedFoodType === 'seasonal',
            category: selectedCategory
        }))

    }, [selectedCategory, selectedFoodType, restaurantId])

  return (
    <>
    <Navbar/>
    <div className='px-5 lg:px-20'>
        <section>
            {/* <h3 className='text-gray-500 py-2 mt-10'>Home/canada/halal yum 1/1</h3> */}
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <img alt='restaurant' className='w-full h-[20vh] object-cover' src={restaurant.restaurant?.images[0]}/>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <img alt='restaurant' className='w-full h-[20vh] object-cover' src={restaurant.restaurant?.images[0]}/>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <img alt='restaurant' className='w-full h-[20vh] object-cover' src={restaurant.restaurant?.images[2]}/>
                    </Grid>
                </Grid>
            </div>
            <div className='pt-3 pb-5'>
                <h1 className='text-4xl font-semibold'>{restaurant.restaurant?.name}</h1>
                <p className='text-gray-500 mt-1'>{restaurant.restaurant?.description}</p>
                <div className='space-y-3 mt-3'>
                <p className='text-gray-500 flex items-center gap-3'>
                    <LocationOnIcon/>
                    <span>Mississauga, Canada</span>
                </p>
                <p className='text-gray-500 flex items-center gap-3'>
                    <CalendarTodayIcon/>
                    <span>Mon-Sun 9:00 AM - 10:00 PM (Today)</span>
                </p>
                </div>
            </div>
        </section>
        <Divider/>
        <section className='pt-[2rem] lg:flex relative'>
            <div className='space-y-10 lg:w-[20%] filter'>
                <div className='box space-y-5 lg:sticky top-28'>
                    <div>
                        <Typography variant='h5' sx={{paddingBottom: "1rem"}}>
                            Food Type
                        </Typography>
                        <FormControl className='py-10 space-y-5' component={"fieldset"}>
                            <RadioGroup onChange={handleFilterFoodType} name='food_type' value={selectedFoodType}>
                                {foodTypes.map((foodType) => <FormControlLabel 
                                key={foodType.label}
                                value={foodType.value} control={<Radio />} label={foodType.label} />)}
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <Divider/>
                    <div>
                        <Typography variant='h5' sx={{paddingBottom: "1rem"}}>
                            Food Category
                        </Typography>
                        <FormControl className='py-10 space-y-5' component={"fieldset"}>
                            <RadioGroup onChange={handleFilterCategory} name='foodCategory' value={selectedCategory}>
                                {restaurant.categories.map((category) => <FormControlLabel 
                                key={category.id}
                                value={category.name} control={<Radio />} label={category.name} />)}
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
            </div>
            <div className='space-y-5 lg:w-[80%] lg:pl-10'>
                {menu.menuItems.map((menuItem) =>  <MenuCard key={menuItem.id} menuItem={menuItem}/>)}
                </div>
        </section>
        <ToastContainer />
    </div>
    </>
  )
}

export default RestaurantDetails