import { api } from "../../component/config/api";
import { CREATE_ORDER_FAILURE, CREATE_ORDER_REQUEST, CREATE_ORDER_SUCCESS, GET_USER_ORDERS_FAILURE, GET_USER_ORDERS_REQUEST, GET_USER_ORDERS_SUCCESS } from "./ActionTypes"

export const createOrder = (data) => {
    return async (dispatch) => {
        dispatch({ type: CREATE_ORDER_REQUEST });
        try {
            const response = await api.post(`/api/order`, data.order, {
                headers: {
                    Authorization: `Bearer ${data.jwt}`,
                },
            });
            if (response.data.paymentUrl) {
                window.location.href = response.data.paymentUrl;
            }
            dispatch({ type: CREATE_ORDER_SUCCESS, payload: response.data });
            console.log('order created successfully', response.data);
        } catch (error) {
            console.log('error creating order', error);
            dispatch({ type: CREATE_ORDER_FAILURE, payload: error });
        }
    }
}

export const createGuestOrder = (data) => {
    return async (dispatch) => {
        dispatch({ type: CREATE_ORDER_REQUEST });
        try {
            const response = await api.post(`/api/order/guest`, data.order);
            if (response.data.paymentUrl) {
                window.location.href = response.data.paymentUrl;
            }
            dispatch({ type: CREATE_ORDER_SUCCESS, payload: response.data });
            console.log('order created successfully', response.data);
        } catch (error) {
            console.log('error creating order', error);
            dispatch({ type: CREATE_ORDER_FAILURE, payload: error });
        }
    }
}

export const getUserOrders = (jwt) => {
    return async (dispatch) => {
        dispatch({ type: GET_USER_ORDERS_REQUEST });
        try {
            const response = await api.get(`/api/order/user`, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            });
            dispatch({ type: GET_USER_ORDERS_SUCCESS, payload: response.data });
            console.log('order fetch successfully', response.data);
        } catch (error) {
            console.log('error fetting user orders', error);
            dispatch({ type: GET_USER_ORDERS_FAILURE, payload: error });
        }
    }
}