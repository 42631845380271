import React, { useEffect } from 'react'
import "./Home.css"
import MultiItemCarousel from './MultiItemCarousel'
import { useDispatch, useSelector } from 'react-redux';
import { getAllRestaurants } from '../../State/Restaurant/Action';
import { MyPlaceAutoComplete } from './MyPlaceAutoComplete';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import VisitUs from '../Visit Us/VisitUs'
import About from '../About Us/AboutUs'
import FlashOrder from '../Flash Order/FlashOrder'
import Menu from '../Menu/Menu'
import { Auth } from '../Auth/Auth'
import {Navbar} from '../Navbar/Navbar'
import Footer from '../Footer/Footer';


// Importing the Halal Yum Logo From the Storage
import Logo from '../../Storage/logo.png';
import Logo1 from "../../Storage/24k.png";

export const Home = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { restaurant } = useSelector(store => store);
    console.log('printing restaurant reducer from store---------', restaurant);

    const handleNavigateToRestaurant = () => {
        if (restaurant.restaurants[0]?.open) {
            navigate(`/restaurant/${restaurant?.restaurants[0]?.address.city}/${restaurant.restaurants[0]?.name}/${restaurant.restaurants[0]?.id}`)
        }
    }

    useEffect(() => {
        dispatch(getAllRestaurants())
    }, [])


  return (
    

    <div className='bg-gradient-to-bl from-yellow-50 to-red-50 '>
        <Navbar/>
        <div className='flex flex-col justify-center items-center bg-gradient-to-br from-yellow-50 to-red-50 h-screen relative'>
            
            <div className='h-[80vh]  w-[90%] flex justify-center items-center absolute top-0'>
                <section className='flex  justify-center'>
                    <div className='flex flex-col  items-center pt-[40px]'>
                        <div className='flex flex-col items-center '>
                            <img src={Logo} alt="" className='w-[120px] sm:w-[180px]'/>
                            <img src={Logo1} alt="Halal Yum Logo" className=' w-[400px] sm:w-[600px] mt-[10px]'/>
                        </div>
                        <p className='text-[19px] sm:text-2xl font-semibold  text-primary font-logo-font  mt-[30px] mb-[15px] tracking-normal'>Where Flavor Meets Quality</p>
                        <div className='flex-col justify-center gap-3'>
                            <MyPlaceAutoComplete className="w-[100px] "/>
                            <Box textAlign='center'><Button sx={{marginTop: 2, padding: '5px 20px 5px 20px', backgroundColor: '#9D8C62', fontSize: '1.2rem', '&:hover': {
                            backgroundColor: '#573a04' },}} onClick={handleNavigateToRestaurant} variant='contained' >Order Now</Button></Box>
                        </div>
                                
                    </div>
                </section>
            </div>

        </div>
        
        <FlashOrder/>
        <About/>
        <Menu/>
        <Auth/>
        <VisitUs/>
        <Footer/>
    </div>



  )
}

export default Home