import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createIngredient } from '../../../State/Ingredients/Action';

const CreateIngredientForm = () => {
    const { restaurant } = useSelector(store => store);
    const dispatch = useDispatch();
    const jwt = localStorage.getItem("jwt");
    const [formData, setFormData] = useState({ ingredientName: "", ingredientCategoryId: "" });
    const { ingredients } = useSelector(store => store);
    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            name: formData.ingredientName,
            restaurantId: restaurant?.restaurants[0]?.id,
            categoryId: formData.ingredientCategoryId
        }
        dispatch(createIngredient({ requestData: data, jwt }))
        console.log(data)
    }
    const handleInputChange = (event) => {
        const { name, value } = event.target
        setFormData({
            ...formData, [name]: value
        })
    }
    return (
        <div className=''>
            <div className='p-5'>
                <h1 className='text-gray-400 text-center text-xl pb-10'>Create Ingredient</h1>
                <form className='space-y-4' onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        id='ingredientName'
                        name='ingredientName'
                        label='Ingredient Name'
                        variant='outlined'
                        onChange={handleInputChange}
                        value={formData.ingredientName}>
                    </TextField>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Ingredient Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formData.ingredientCategoryId}
                            name="ingredientCategoryId"
                            label="Ingredient Category"
                            onChange={handleInputChange}
                        > {ingredients.category.map((ingredientCategory) => <MenuItem key={ingredientCategory.id} value={ingredientCategory.id}>{ingredientCategory.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <Button variant='contained' type='submit'>Create Ingredient</Button>
                </form>
            </div>
        </div>
    )
}

export default CreateIngredientForm