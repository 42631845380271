import axios from "axios"
import { ADD_TO_FAVORITE_FAILURE, ADD_TO_FAVORITE_REQUEST, ADD_TO_FAVORITE_SUCCESS, GET_USER_FAILURE, GET_USER_REQUEST, GET_USER_SUCCESS, LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT, REGISTER_FAILURE, REGISTER_REQUEST, REGISTER_SUCCESS, RESET_PASSWORD_FAILURE, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, SAVE_PASSWORD_FAILURE, SAVE_PASSWORD_REQUEST, SAVE_PASSWORD_SUCCESS } from "./ActionTypes"
import { BASE_API_URL, api } from "../../component/config/api"

export const registerUser = (requestData) => async (dispatch) => {
    dispatch({ type: REGISTER_REQUEST })
    try {
        const { data } = await axios.post(`${BASE_API_URL}/api/auth/signup`, requestData.userData);
        if (data.jwt) {
            localStorage.setItem("jwt", data.jwt);
        }
        if (data.role === 'ROLE_RESTAURANT_OWNER') {
            requestData.navigate("/admin/restaurants")
        } else {
            requestData.navigate("/");
        }
        dispatch({ type: REGISTER_SUCCESS, payload: data.jwt })
        console.log("register success", data);

    } catch (error) {
        dispatch({ type: REGISTER_FAILURE, payload: error });
        console.log("error", error)
    }
}

export const loginUser = (requestData) => async (dispatch) => {
    dispatch({ type: LOGIN_REQUEST })
    try {
        const { data } = await axios.post(`${BASE_API_URL}/api/auth/signin`, requestData.userData);
        if (data.jwt) {
            localStorage.setItem("jwt", data.jwt);
        }
        if (data.role === 'ROLE_RESTAURANT_OWNER') {
            requestData.navigate("/");
        }
        if (data.role === 'ROLE_CUSTOMER') {
            requestData.navigate("/");
        }
        dispatch({ type: LOGIN_SUCCESS, payload: data.jwt })
        console.log("Login success", data);

    } catch (error) {
        dispatch({ type: LOGIN_FAILURE, payload: error });
        console.log("error", error)
    }
}

export const resetpassword = (requestData) => async (dispatch) => {
    dispatch({ type: RESET_PASSWORD_REQUEST })
    try {
        const { data } = await axios.post(`${BASE_API_URL}/api/auth/resetpassword`, requestData.userData);
        dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data.jwt })
        console.log("reset Password Link Sent success", data);

    } catch (error) {
        dispatch({ type: RESET_PASSWORD_FAILURE, payload: error });
        console.log("error", error)
    }
}

export const savePassword = (requestData) => async (dispatch) => {
    dispatch({ type: SAVE_PASSWORD_REQUEST })
    try {
        const { data } = await axios.post(`${BASE_API_URL}/api/auth/savepassword`, requestData.userData);
        dispatch({ type: SAVE_PASSWORD_SUCCESS, payload: data.jwt })
        console.log("reset Password Link Sent success", data);

    } catch (error) {
        dispatch({ type: SAVE_PASSWORD_FAILURE, payload: error });
        console.log("error", error)
    }
}

export const getUser = (jwt) => async (dispatch) => {
    dispatch({ type: GET_USER_REQUEST })
    try {
        const { data } = await api.get(`/api/users/profile`, {
            headers: {
                Authorization: `Bearer ${jwt}`
            }
        });
        dispatch({ type: GET_USER_SUCCESS, payload: data })
        console.log("user profile", data);
    } catch (error) {
        dispatch({ type: GET_USER_FAILURE, payload: error });
        console.log("error", error)
    }
}

export const addToFavorite = ({ jwt, restaurantId }) => async (dispatch) => {
    dispatch({ type: ADD_TO_FAVORITE_REQUEST })
    try {
        const { data } = await api.put(`/api/restaurants/${restaurantId}/add-favourite`, {}, {
            headers: {
                Authorization: `Bearer ${jwt}`
            }
        });
        dispatch({ type: ADD_TO_FAVORITE_SUCCESS, payload: data })
        console.log("Added to Favorites", data);
    } catch (error) {
        dispatch({ type: ADD_TO_FAVORITE_FAILURE, payload: error });
        console.log("error", error)
    }
}

export const logout = () => async (dispatch) => {
    try {
        localStorage.clear()
        dispatch({ type: LOGOUT })
        console.log("logout success");
    } catch (error) {
        console.log("error", error)
    }
}

