export const GET_RESTAURANTS_ORDER_REQUEST = 'GET_RESTAURANTS_ORDER_REQUEST';
export const GET_RESTAURANTS_ORDER_SUCCESS = 'GET_RESTAURANTS_ORDER_SUCCESS';
export const GET_RESTAURANTS_ORDER_FAILURE = 'GET_RESTAURANTS_ORDER_FAILURE';

export const GET_PENDING_RESTAURANTS_ORDER_REQUEST = 'GET_PENDING_RESTAURANTS_ORDER_REQUEST';
export const GET_PENDING_RESTAURANTS_ORDER_SUCCESS = 'GET_PENDING_RESTAURANTS_ORDER_SUCCESS';
export const GET_PENDING_RESTAURANTS_ORDER_FAILURE = 'GET_PENDING_RESTAURANTS_ORDER_FAILURE';

export const UPDATE_ORDER_STATUS_REQUEST = 'UPDATE_ORDER_STATUS_REQUEST';
export const UPDATE_ORDER_STATUS_SUCCESS = 'UPDATE_ORDER_STATUS_SUCCESS';
export const UPDATE_ORDER_STATUS_FAILURE = 'UPDATE_ORDER_STATUS_FAILURE';