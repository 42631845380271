export const GET_INGREDIENTS_REQUEST = 'GET_INGREDIENTS_REQUEST';
export const GET_INGREDIENTS_SUCCESS = 'GET_INGREDIENTS_SUCCESS';
export const GET_INGREDIENTS_FAILURE = 'GET_INGREDIENTS_FAILURE';

export const UPDATE_STOCK_REQUEST = 'UPDATE_STOCK_REQUEST';
export const UPDATE_STOCK_SUCCESS = 'UPDATE_STOCK_SUCCESS';
export const UPDATE_STOCK_FAILURE = 'UPDATE_STOCK_FAILURE';

export const CREATE_INGREDIENT_REQUEST = 'CREATE_INGREDIENT_REQUEST';
export const CREATE_INGREDIENT_SUCCESS = 'CREATE_INGREDIENT_SUCCESS';
export const CREATE_INGREDIENT_FAILURE = 'CREATE_INGREDIENT_FAILURE';

export const DELETE_INGREDIENT_REQUEST = 'DELETE_INGREDIENT_REQUEST';
export const DELETE_INGREDIENT_SUCCESS = 'DELETE_INGREDIENT_SUCCESS';
export const DELETE_INGREDIENT_FAILURE = 'DELETE_INGREDIENT_FAILURE';


export const CREATE_INGREDIENT_CATEGORY_REQUEST = 'CREATE_INGREDIENT_CATEGORY_REQUEST';
export const CREATE_INGREDIENT_CATEGORY_SUCCESS = 'CREATE_INGREDIENT_CATEGORY_SUCCESS';
export const CREATE_INGREDIENT_CATEGORY_FAILURE = 'CREATE_INGREDIENT_CATEGORY_FAILURE';

export const DELETE_INGREDIENT_CATEGORY_REQUEST = 'DELETE_INGREDIENT_CATEGORY_REQUEST';
export const DELETE_INGREDIENT_CATEGORY_SUCCESS = 'DELETE_INGREDIENT_CATEGORY_SUCCESS';
export const DELETE_INGREDIENT_CATEGORY_FAILURE = 'DELETE_INGREDIENT_CATEGORY_FAILURE';


export const GET_INGREDIENT_CATEGORY_REQUEST = 'GET_INGREDIENT_CATEGORY_REQUEST';
export const GET_INGREDIENT_CATEGORY_SUCCESS = 'GET_INGREDIENT_CATEGORY_SUCCESS';
export const GET_INGREDIENT_CATEGORY_FAILURE = 'GET_INGREDIENT_CATEGORY_FAILURE';