import { api } from "../../component/config/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CREATE_MENU_ITEM_FAILURE, CREATE_MENU_ITEM_REQUEST, CREATE_MENU_ITEM_SUCCESS, DELETE_MENU_ITEM_FAILURE, DELETE_MENU_ITEM_REQUEST, DELETE_MENU_ITEM_SUCCESS, GET_MENU_ITEMS_BY_RESTAURANT_ID_FAILURE, GET_MENU_ITEMS_BY_RESTAURANT_ID_REQUEST, GET_MENU_ITEMS_BY_RESTAURANT_ID_SUCCESS, SEARCH_MENU_ITEM_FAILURE, SEARCH_MENU_ITEM_REQUEST, SEARCH_MENU_ITEM_SUCCESS, UPDATE_MENU_ITEM_AVAILABILITY_FAILURE, UPDATE_MENU_ITEM_AVAILABILITY_REQUEST, UPDATE_MENU_ITEM_AVAILABILITY_SUCCESS } from "./ActionTypes";

export const createMenuItem = ({ menu, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: CREATE_MENU_ITEM_REQUEST })
        try {
            const response = await api.post(`api/admin/food`, menu, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            });
            console.log('created menu', response);
            dispatch({ type: CREATE_MENU_ITEM_SUCCESS, payload: response.data });
            toast.success('Menu Item Created Successfully!')
        } catch (error) {
            console.log('error while creating menu item', error);
            dispatch({ type: CREATE_MENU_ITEM_FAILURE, payload: error });
            toast.error('Menu Item Creation Failed')
        }
    }
}

export const getMenuItemByRestaurantId = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: GET_MENU_ITEMS_BY_RESTAURANT_ID_REQUEST })
        try {
            const response = await api.get(`/api/food/restaurant/${requestData.restaurantId}`);
            dispatch({ type: GET_MENU_ITEMS_BY_RESTAURANT_ID_SUCCESS, payload: response.data });
            console.log('successfully fetch menu of restaurant', response.data);
        } catch (error) {
            console.log('error getting menu item by restaurant id', error);
            dispatch({ type: GET_MENU_ITEMS_BY_RESTAURANT_ID_FAILURE, payload: error });
        }
    }
}

export const getMenuItemByRestaurantIdAndFilter = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: GET_MENU_ITEMS_BY_RESTAURANT_ID_REQUEST })
        try {
            const response = await api.get(`/api/food/restaurant/${requestData.restaurantId}?vegetarian=${requestData.vegetarian}&nonVegetarian=${requestData.nonVegetarian}&seasonal=${requestData.seasonal}&foodCategory=${requestData.category}`);
            dispatch({ type: GET_MENU_ITEMS_BY_RESTAURANT_ID_SUCCESS, payload: response.data });
            console.log('successfully fetch menu of restaurant', response.data);
        } catch (error) {
            console.log('error getting menu item by restaurant id', error);
            dispatch({ type: GET_MENU_ITEMS_BY_RESTAURANT_ID_FAILURE, payload: error });
        }
    }
}

export const searchMenuItem = ({ keyword }) => {
    return async (dispatch) => {
        dispatch({ type: SEARCH_MENU_ITEM_REQUEST });
        try {
            const response = await api.get(`/api/food/search?name=${keyword}`);
            dispatch({ type: SEARCH_MENU_ITEM_SUCCESS, payload: response.data });
        } catch (error) {
            console.log('error seaching menu item ', error);
            dispatch({ type: SEARCH_MENU_ITEM_FAILURE, payload: error });
        }
    }
}

// export const getAllIngredientsOfMenuItem = ({menuItem, jwt}) => {
//     return async (dispatch) => {
//         dispatch({type: })
//     }
// }

export const updateMenuItemAvailability = ({ foodId, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_MENU_ITEM_AVAILABILITY_REQUEST });
        try {
            const response = await api.put(`/api/admin/food/${foodId}`, {}, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            });
            dispatch({ type: UPDATE_MENU_ITEM_AVAILABILITY_SUCCESS, payload: response.data });
            console.log('successfully updated menu item availability', response.data);
            toast.success('Menu Item Availability Updated !')
        } catch (error) {
            console.log('error updating menu item availability', error);
            dispatch({ type: UPDATE_MENU_ITEM_AVAILABILITY_FAILURE, payload: error });
            toast.error('Menu Item Update Failed')
        }
    }
}

export const deleteMenuItem = ({ foodId, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: DELETE_MENU_ITEM_REQUEST });
        try {
            await api.delete(`/api/admin/food/${foodId}`, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            })
            dispatch({ type: DELETE_MENU_ITEM_SUCCESS, payload: foodId });
            console.log('successfully delete menu item', foodId);
            toast.success('Menu Item Deleted Successfully!')
        } catch (error) {
            console.log('error deleting food', error);
            dispatch({ type: DELETE_MENU_ITEM_FAILURE, payload: error });
            toast.error('Menu Item Deletion Failed!')
        }
    }
}