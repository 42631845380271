import { toast } from "react-toastify";
import { api } from "../../component/config/api";
import { CREATE_CATEGORY_FAILURE, CREATE_CATEGORY_REQUEST, CREATE_CATEGORY_SUCCESS, CREATE_EVENT_FAILURE, CREATE_EVENT_REQUEST, CREATE_EVENT_SUCCESS, CREATE_RESTAURANT_FAILURE, CREATE_RESTAURANT_REQUEST, CREATE_RESTAURANT_SUCCESS, DELETE_EVENT_FAILURE, DELETE_EVENT_REQUEST, DELETE_EVENT_SUCCESS, DELETE_FOOD_CATEGORY_FAILURE, DELETE_FOOD_CATEGORY_REQUEST, DELETE_FOOD_CATEGORY_SUCCESS, DELETE_RESTAURANT_FAILURE, DELETE_RESTAURANT_REQUEST, DELETE_RESTAURANT_SUCCESS, GET_ALL_EVENTS_FAILURE, GET_ALL_EVENTS_REQUEST, GET_ALL_EVENTS_SUCCESS, GET_ALL_RESTAURANTS_FAILURE, GET_ALL_RESTAURANTS_REQUEST, GET_ALL_RESTAURANTS_SUCCESS, GET_RESTAURANTS_CATEGORY_FAILURE, GET_RESTAURANTS_CATEGORY_REQUEST, GET_RESTAURANTS_CATEGORY_SUCCESS, GET_RESTAURANTS_EVENTS_FAILURE, GET_RESTAURANTS_EVENTS_REQUEST, GET_RESTAURANTS_EVENTS_SUCCESS, GET_RESTAURANT_BY_ID_FAILURE, GET_RESTAURANT_BY_ID_REQUEST, GET_RESTAURANT_BY_ID_SUCCESS, GET_RESTAURANT_BY_USER_ID_FAILURE, GET_RESTAURANT_BY_USER_ID_REQUEST, GET_RESTAURANT_BY_USER_ID_SUCCESS, UPDATE_RESTAURANT_FAILURE, UPDATE_RESTAURANT_REQUEST, UPDATE_RESTAURANT_STATUS_FAILURE, UPDATE_RESTAURANT_STATUS_REQUEST, UPDATE_RESTAURANT_STATUS_SUCCESS, UPDATE_RESTAURANT_SUCCESS } from "./ActionTypes"

export const getAllRestaurants = () => {
    return async (dispatch) => {
        dispatch({ type: GET_ALL_RESTAURANTS_REQUEST });
        try {
            const { data } = await api.get("/api/restaurants");
            dispatch({ type: GET_ALL_RESTAURANTS_SUCCESS, payload: data });
            console.log("all restaurants ", data);
        } catch (error) {
            console.log("errors :", error);
            dispatch({ type: GET_ALL_RESTAURANTS_FAILURE, payload: error });
        }
    }
}

export const getRestaurantById = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: GET_RESTAURANT_BY_ID_REQUEST });
        try {
            const response = await api.get(`/api/restaurants/${requestData.restaurantId}`);
            dispatch({ type: GET_RESTAURANT_BY_ID_SUCCESS, payload: response.data })
        } catch (error) {
            console.log('get restaurant by id error :', error);
            dispatch({ type: GET_RESTAURANT_BY_ID_FAILURE, payload: error });
        }
    }
}

export const getRestaurantByUserId = (jwt) => {
    return async (dispatch) => {
        dispatch({ type: GET_RESTAURANT_BY_USER_ID_REQUEST });
        try {
            const { data } = await api.get(`/api/admin/restaurants/user`, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            });
            console.log("get restaurant by user id ", data);
            dispatch({ type: GET_RESTAURANT_BY_USER_ID_SUCCESS, payload: data });
        } catch (error) {
            console.log("error catch ", error);
            dispatch({ type: GET_RESTAURANT_BY_USER_ID_FAILURE, payload: error });
        }
    }
}

export const createRestaurant = (requestData) => {
    console.log("create restaurant request data is ", requestData);
    return async (dispatch) => {
        dispatch({ type: CREATE_RESTAURANT_REQUEST });
        try {
            const { data } = await api.post(`/api/admin/restaurants`, requestData.data, {
                headers: {
                    Authorization: `Bearer ${requestData.token}`,
                },
            });
            dispatch({ type: CREATE_RESTAURANT_SUCCESS, payload: data });
        } catch (error) {
            console.log('error while creating restaurant', error);
            dispatch({ type: CREATE_RESTAURANT_FAILURE, payload: error });
        }
    }
}

export const updateRestaurant = ({ restaurantId, restaurantData, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_RESTAURANT_REQUEST });
        try {
            const { response } = await api.put(`/api/admin/restaurant/${restaurantId}`, restaurantData, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            });
            dispatch({ type: UPDATE_RESTAURANT_SUCCESS, payload: response.data });
        } catch (error) {
            console.log('error updating restaurant ', error);
            dispatch({ type: UPDATE_RESTAURANT_FAILURE, payload: error });
        }
    }
}

export const deleteRestaurant = ({ restaurantId, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: DELETE_RESTAURANT_REQUEST });
        try {
            const { response } = await api.delete(`/api/restaurant/${restaurantId}`, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            });
            dispatch({ type: DELETE_RESTAURANT_SUCCESS, payload: response.data });
        } catch (error) {
            console.log('restaurant delete failed ', error);
            dispatch({ type: DELETE_RESTAURANT_FAILURE, payload: error });
        }
    }
}

export const updateRestaurantStatus = ({ restaurantId, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_RESTAURANT_STATUS_REQUEST });
        try {
            const response = await api.put(`/api/admin/restaurants/${restaurantId}/status`, {}, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            });
            dispatch({ type: UPDATE_RESTAURANT_STATUS_SUCCESS, payload: response.data });
        } catch (error) {
            console.log('update restaurant error :', error);
            dispatch({ type: UPDATE_RESTAURANT_STATUS_FAILURE, payload: error });
        }
    }
}

export const createEvent = ({ requestData, restaurantId, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: CREATE_EVENT_REQUEST });
        try {
            const response = await api.post(`/api/admin/events/restaurant/${restaurantId}`, requestData, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            });
            dispatch({ type: CREATE_EVENT_SUCCESS, payload: response.data });
        } catch (error) {
            console.log('error creating event', error);
            dispatch({ type: CREATE_EVENT_FAILURE, payload: error });
        }
    }
}

export const getAllEvents = ({ jwt }) => {
    return async (dispatch) => {
        dispatch({ type: GET_ALL_EVENTS_REQUEST });
        try {
            const response = await api.get(`/api/events`, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            });
            dispatch({ type: GET_ALL_EVENTS_SUCCESS, payload: response.data });
        } catch (error) {
            console.log("error getting all the events", error);
            dispatch({ type: GET_ALL_EVENTS_FAILURE, payload: error });
        }
    }
}

export const deleteEvent = ({ eventId, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: DELETE_EVENT_REQUEST });
        try {
            const response = await api.delete(`api/admin/events/${eventId}`, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            });
            dispatch({ type: DELETE_EVENT_SUCCESS, payload: response.data });
        } catch (error) {
            console.log('error deleting event', error);
            dispatch({ type: DELETE_EVENT_FAILURE, payload: error });
        }
    }
}

export const getRestaurantsEvents = ({ restaurantId, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: GET_RESTAURANTS_EVENTS_REQUEST });
        try {
            const response = await api.get(`/api/admin/events/restaurant/${restaurantId}`, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            });
            dispatch({ type: GET_RESTAURANTS_EVENTS_SUCCESS, payload: response.data });
        } catch (error) {
            console.log('error fetching restaurants events', error);
            dispatch({ type: GET_RESTAURANTS_EVENTS_FAILURE, payload: error });
        }
    }
}

export const createCategory = ({ requestData, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: CREATE_CATEGORY_REQUEST });
        try {
            const response = await api.post(`/api/admin/category`, requestData, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            });
            dispatch({ type: CREATE_CATEGORY_SUCCESS, payload: response.data });
        } catch (error) {
            console.log('error creating category', error);
            dispatch({ type: CREATE_CATEGORY_FAILURE, payload: error })
        }
    }
}

export const deleteFoodCategory = ({ foodCategoryId, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: DELETE_FOOD_CATEGORY_REQUEST });
        try {
            await api.delete(`/api/admin/category/${foodCategoryId}`, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            })
            dispatch({ type: DELETE_FOOD_CATEGORY_SUCCESS, payload: foodCategoryId });
            console.log('successfully delete Food Category', foodCategoryId);
            toast.success('Food Category Deleted Successfully!')
        } catch (error) {
            console.log('error deleting food category', error);
            dispatch({ type: DELETE_FOOD_CATEGORY_FAILURE, payload: error });
            toast.warn('Food Category Might Be In Use')
        }
    }
}

export const getRestaurantsCategory = ({ restaurantId }) => {
    return async (dispatch) => {
        dispatch({ type: GET_RESTAURANTS_CATEGORY_REQUEST });
        try {
            const response = await api.get(`/api/category/restaurant/${restaurantId}`);
            dispatch({ type: GET_RESTAURANTS_CATEGORY_SUCCESS, payload: response.data });
        } catch (error) {
            console.log('error fetching restaurants categories', error);
            dispatch({ type: GET_RESTAURANTS_CATEGORY_FAILURE, payload: error });
        }
    }
}