import React, { useEffect } from 'react'
import AdminSideBar from './AdminSideBar'
import { Route, Routes } from 'react-router-dom'
import Orders from '../Orders/Orders'
import Menu from '../Menu/Menu'
import Events from '../Events/Events'
import FoodCategory from '../FoodCategory/FoodCategory'
import Ingredients from '../Ingredients/Ingredients'
import RestaurantDetails from './RestaurantDetails'
import RestaurantDashboard from '../RestaurantDashboard/RestaurantDashboard'
import CreateMenuForm from '../Menu/CreateMenuForm'
import { useDispatch, useSelector } from 'react-redux'
import { getRestaurantsCategory } from '../../../State/Restaurant/Action'
import { fetchRestaurantOrders } from '../../../State/RestaurantOrder/Action'
import PricesSizes from '../SizesPrices/PricesSizes'
import CreateRestaurantForm from '../CreateRestaurantForm/CreateRestaurantForm'
import { Navbar } from '../../Navbar/Navbar'

const Admin = () => {
  const { restaurant } = useSelector(store => store);
  const jwt = localStorage.getItem("jwt");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRestaurantsCategory({ jwt, restaurantId: restaurant?.restaurants[0]?.id }));
    dispatch(fetchRestaurantOrders({ restaurantId: restaurant?.restaurants[0]?.id, jwt }))
  }, [])
  return (
    <>
    <Navbar/>
    <div>
      
        <div className='lg:flex justify-between z-0'>
            
            <div className='z-0'>
              <AdminSideBar/>
            </div>
            <div className='lg:w-[100%]'>
              <Routes>
                <Route path='/:restaurantId' element={<RestaurantDashboard/>}></Route>
                <Route path='/createrestaurant' element={<CreateRestaurantForm/>}></Route>
                <Route path='/:restaurantId/orders' element={<Orders/>}></Route>
                <Route path='/:restaurantId/menu' element={<Menu/>}></Route>
                <Route path='/:restaurantId/foodcategory' element={<FoodCategory/>}></Route>
                <Route path='/:restaurantId/ingredients' element={<Ingredients/>}></Route>
                <Route path='/:restaurantId/pricessizes' element={<PricesSizes/>}></Route>
                <Route path='/:restaurantId/events' element={<Events/>}></Route>
                <Route path='/:restaurantId/restaurantdetails' element={<RestaurantDetails/>}></Route>
                <Route path='/:restaurantId/add-menu' element={<CreateMenuForm/>}></Route>
              </Routes>
            </div>
        </div>
    </div>
    </>
  )
}

export default Admin


