import { isPresentInFavorites } from "../../component/config/logic";
import { ADD_TO_FAVORITE_FAILURE, ADD_TO_FAVORITE_REQUEST, ADD_TO_FAVORITE_SUCCESS, GET_USER_FAILURE, GET_USER_REQUEST, GET_USER_SUCCESS, LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, REGISTER_FAILURE, REGISTER_REQUEST, REGISTER_SUCCESS, LOGOUT, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE, SAVE_PASSWORD_REQUEST, SAVE_PASSWORD_SUCCESS, SAVE_PASSWORD_FAILURE } from "./ActionTypes"

const initialState = {
    user: null,
    isLoading: false,
    error: null,
    jwt: null,
    favorites: [],
    success: null
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER_REQUEST:
        case LOGIN_REQUEST:
        case GET_USER_REQUEST:
        case RESET_PASSWORD_REQUEST:
        case ADD_TO_FAVORITE_REQUEST:
        case SAVE_PASSWORD_REQUEST:
            return { ...state, isLoading: true, error: null, success: null }
        case SAVE_PASSWORD_SUCCESS:
            return { ...state, isLoading: false, error: null, success: "Password Saved Successfully !" }
        case RESET_PASSWORD_SUCCESS:
            return { ...state, isLoading: false, error: null, success: "Reset Password Link Sent !" }
        case REGISTER_SUCCESS:
            return { ...state, isLoading: false, jwt: action.payload, success: "Login Success" };
        case LOGIN_SUCCESS:
            return { ...state, isLoading: false, jwt: action.payload, success: "Register Success" };
        case GET_USER_SUCCESS:
            return { ...state, isLoading: false, user: action.payload, favorites: action.payload.favourites };
        case ADD_TO_FAVORITE_SUCCESS:
            return {
                ...state, isLoading: false, error: null, favorites: isPresentInFavorites(state.favorites, action.payload) ?
                    state.favorites.filter((item) => item.id !== action.payload.id) : [action.payload, ...state.favorites]
            };
        case LOGOUT:
            return initialState;
        case REGISTER_FAILURE:
        case LOGIN_FAILURE:
        case GET_USER_FAILURE:
        case ADD_TO_FAVORITE_FAILURE:
        case RESET_PASSWORD_FAILURE:
        case SAVE_PASSWORD_FAILURE:
            return { ...state, isLoading: false, error: action.payload, success: null }
        default:
            return state;
    }
}