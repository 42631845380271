import React, { useEffect } from 'react'
import MultiItemCarousel from './MultiItemCarousel'
import { useDispatch, useSelector } from 'react-redux';
import { getAllRestaurants } from '../../State/Restaurant/Action';
import { MyPlaceAutoComplete } from './MyPlaceAutoComplete';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Line from "../../Storage/line.png";

export default function FlashOrder() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {restaurant} = useSelector(store => store);
    console.log('printing restaurant reducer from store---------', restaurant);

    const handleNavigateToRestaurant = () => {
        if(restaurant.restaurants[0]?.open) {
            navigate(`/restaurant/${restaurant?.restaurants[0]?.address.city}/${restaurant.restaurants[0]?.name}/${restaurant.restaurants[0]?.id}`)
        }
    }

    useEffect(() => {
        dispatch(getAllRestaurants())
    }, [])
    return(
        <div className='py-[0px] lg:py-[100px] bg-white'>
            <div className='flex flex-col justify-center items-center'>
                
                <div className="flex flex-col justify-center items-center mt-[100px]">
                    <h1 className="font-logo-font text-primary font-semibold text-3xl sm:text-4xl md:text-5xl mb-[10px]">Flash Order</h1>
                    <img src={Line} alt="line" className="w-[250px] h-[10px]  sm:w-[350px] sm:h-[20px]"/>
                </div>
            </div>
            <section className='p-10 lg:py-10 lg:px-20' onClick={handleNavigateToRestaurant}>
                    <p className='font-logo-font text-black text-[20px] sm:text-2xl text-center mb-[50px] font-semibold'>Our Top Meals, Just a Click Away</p>
                    <MultiItemCarousel />
            </section>
        </div>
    )


}