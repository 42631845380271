import { Box, Card, CardHeader, IconButton, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create';
import React, { useEffect } from 'react'
import CreateIngredientCategoryForm from './CreateIngredientCategoryForm';
import { useDispatch, useSelector } from 'react-redux';
import { deleteIngredientCategory, getIngredientCategory } from '../../../State/Ingredients/Action';
import AreYouSureModal from '../../modal/AreYouSureModal';
import { useParams } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const IngredientsCategoryTable = () => {
  const { ingredients } = useSelector(store => store);
  console.log('ingredients from store', ingredients);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const { restaurantId } = useParams();
  const jwt = localStorage.getItem("jwt");

  useEffect(() => {
    dispatch(getIngredientCategory({ restaurantId: restaurantId, jwt }));
  }, [])

  const handleDeleteIngredientCategory = (ingredientCategoryId) => {
    dispatch(deleteIngredientCategory({ ingredientCategoryId, jwt }))
  }

  return (
    <Box>
      <Card className='mt-1'>
        <CardHeader action={
          <IconButton onClick={handleOpen} aria-label="settings"> <CreateIcon></CreateIcon></IconButton>}
          title='Ingredients Category'
          sx={{ pt: 2, alignItems: "center" }} />
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">ID</TableCell>
                <TableCell align="left">Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ingredients.category.map((ingredientCategory) => (
                <TableRow key={ingredientCategory.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="left">{ingredientCategory.id}</TableCell>
                  <TableCell align="left">{ingredientCategory.name}</TableCell>
                  <TableCell align="left"><AreYouSureModal onAgreeClick={() => handleDeleteIngredientCategory(ingredientCategory.id)} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CreateIngredientCategoryForm />
        </Box>
      </Modal>
    </Box>
  )
}

export default IngredientsCategoryTable