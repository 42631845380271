export const FIND_GUEST_CART_REQUEST = 'FIND_GUEST_CART_REQUEST';
export const FIND_GUEST_CART_SUCCESS = 'FIND_GUEST_CART_SUCCESS';
export const FIND_GUEST_CART_FAILURE = 'FIND_GUEST_CART_FAILURE';

export const CLEAR_GUEST_CART_REQUEST = 'CLEAR_GUEST_CART_REQUEST';
export const CLEAR_GUEST_CART_SUCCESS = 'CLEAR_GUEST_CART_SUCCESS';
export const CLEAR_GUEST_CART_FAILURE = 'CLEAR_GUEST_CART_FAILURE';

export const GET_ALL_GUEST_CART_ITEMS_REQUEST = 'GET_ALL_GUEST_CART_ITEMS_REQUEST';
export const GET_ALL_GUEST_CART_ITEMS_SUCCESS = 'GET_ALL_GUEST_CART_ITEMS_SUCCESS';
export const GET_ALL_GUEST_CART_ITEMS_FAILURE = 'GET_ALL_GUEST_CART_ITEMS_FAILURE';

export const ADD_ITEM_TO_GUEST_CART_REQUEST = 'ADD_ITEM_TO_GUEST_CART_REQUEST';
export const ADD_ITEM_TO_GUEST_CART_SUCCESS = 'ADD_ITEM_TO_GUEST_CART_SUCCESS';
export const ADD_ITEM_TO_GUEST_CART_FAILURE = 'ADD_ITEM_TO_GUEST_CART_FAILURE';


export const UPDATE_GUEST_CART_ITEM_REQUEST = 'UPDATE_GUEST_CART_ITEM_REQUEST';
export const UPDATE_GUEST_CART_ITEM_SUCCESS = 'UPDATE_GUEST_CART_ITEM_SUCCESS';
export const UPDATE_GUEST_CART_ITEM_FAILURE = 'UPDATE_GUEST_CART_ITEM_FAILURE';

export const REMOVE_GUEST_CART_ITEM_REQUEST = 'REMOVE_GUEST_CART_ITEM_REQUEST';
export const REMOVE_GUEST_CART_ITEM_SUCCESS = 'REMOVE_GUEST_CART_ITEM_SUCCESS';
export const REMOVE_GUEST_CART_ITEM_FAILURE = 'REMOVE_GUEST_CART_ITEM_FAILURE';