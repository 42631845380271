import { Button, TextField, Typography } from '@mui/material'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resetpassword } from '../../State/Authentication/Action'
import * as Yup from 'yup'

const initialValues = {
    email: ""
}

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required')
});

const ResetPasswordForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { auth } = useSelector(store => store);
    const handleSubmit = (values) => {
        dispatch(resetpassword({ userData: values, navigate }))
    }
    return (
        <div>
            <Typography variant='h5' className='text-center'>
                Reset Password
            </Typography>
            <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
                <Form>
                    <Field as={TextField}
                        name="email"
                        label="Email"
                        fullWidth
                        variant="outlined"
                        margin="normal"
                    /><ErrorMessage name="email" component="div" className="error" />
                    <Button fullWidth type='submit' variant='contained' sx={{ mt: 2, padding: "1rem" }}>Send Reset Link</Button>
                </Form>
            </Formik>
            <Typography variant='body2' align='center' sx={{ mt: 3 }}>
                Don't have an account?
                <Button size='small' onClick={() => navigate("/account/register")}>
                    Register
                </Button>
            </Typography>
            <div className='text-center text-green-500 mt-5'>{auth?.success}</div>
            <div className='text-center text-red-500 mt-5'>{auth?.error?.response?.data?.error}</div>
        </div>
    )
}

export default ResetPasswordForm