import { Button, TextField, Typography } from '@mui/material'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { loginUser } from '../../State/Authentication/Action'

const initialValues = {
    email: "",
    password: ""
}

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    password: Yup.string().required("Please Enter a password")
        .min(8, 'Password is too short')
});

const LoginForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { auth } = useSelector(store => store);

    const handleSubmit = (values) => {
        dispatch(loginUser({ userData: values, navigate }))
    }
  return (
    <div className=''>
        <Typography variant='h5' className='text-center'>
            Login
        </Typography>
        <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
            <Form>
                <Field as={TextField}
                            name="email"
                            label="Email"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            />
                <ErrorMessage name="email" component="div" className="error" />
                <Field as={TextField}
                            name="password"
                            label="Password"
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            type="password"
                            /><ErrorMessage name="password" component="div" className="error" />
                            <Button fullWidth type='submit' variant='contained' sx={{mt:2, padding:"1rem"}}>Login</Button>        
            </Form>
        </Formik>

        <Typography variant='body2' align='center' sx={{mt:3}}>
            Don't have an account?
            <Button size='small' onClick={() => navigate("/account/register")}>
                Register
            </Button>
            <Button size='small' onClick={() => navigate("/account/resetpassword")}>
                Reset Password
            </Button>
        </Typography>
        <div className='text-center text-red-500 mt-5'>{auth?.error?.response?.data?.error}</div>
    </div>
  )
}

export default LoginForm