
import Line from "../../Storage/line.png";

import MenuItem from "./MenuItem";

import { menuItems } from "../../constants";

export default function Menu(){
    return(
        <div className="">
            <div className=" bg-[#ffffff] flex justify-center items-center text-black ">
                <div className=" py-[50px]  flex flex-col justify-center items-center w-full">
                    <p className="font-logo-font text-primary font-semibold text-3xl sm:text-4xl md:text-5xl  text-center">Menu</p>
                    <div className="flex justify-center">
                        <img src={Line} alt="line" className="w-[250px] h-[10px]  sm:w-[350px] sm:h-[20px]"/>
                    </div>

                    
                    <div className="w-full">
                        {menuItems.map((menuItem, itemIndex) => (
                            <MenuItem key={itemIndex}
                            image={menuItem.image}
                            mainHeading={menuItem.mainHeading}
                            mainHeadingDesc={menuItem.mainHeadingDesc}
                            data={menuItem.data}
                            index={itemIndex}

                            />
                        ))}
                    </div>
                    
                    
                </div>
            </div>
        </div>
        
           
       
    )
}