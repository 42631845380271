import { Card, Chip, IconButton } from '@mui/material'
import React from 'react'
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToFavorite } from '../../State/Authentication/Action';
import { isPresentInFavorites } from '../config/logic';

const RestaurantCard = ({ restaurant }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const jwt = localStorage.getItem("jwt");
    const { auth } = useSelector(store => store);
    console.log('printing auth state from store', auth);
    const handleAddToFavorite = () => {
        dispatch(addToFavorite({ jwt, restaurantId: restaurant.id }))
    }

    const handleNavigateToRestaurant = () => {
        if (restaurant.open) {
            navigate(`/restaurant/${restaurant.address.city}/${restaurant.name}/${restaurant.id}`)
        }
    }
    return (
        <Card className='w-[18rem]'>
            <div onClick={handleNavigateToRestaurant} className={`${true ? 'cursor-pointer' : 'cursor-not-allowed'} relative`}>
                <img
                    className='w-full h-[10rem] rounded-t-md object-cover'
                    src={''}
                    alt='' />
                <Chip
                    size='small' className='absolute top-2 left-2'
                    color={restaurant.open ? 'success' : 'error'}
                    label={restaurant.open ? 'open' : 'closed'} />
            </div>
            <div className='p-4 textPart lg:flex w-full justify-between'>
                <div className='space-y-1'>
                    <p className='font-semibold text-lg
                cursor-pointer'>{restaurant.name}</p>
                    <p className='text-gray-500 text-sm'>{restaurant.description}</p>
                </div>
                <div>
                    <IconButton onClick={handleAddToFavorite}>
                        {isPresentInFavorites(auth.favorites, restaurant) ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                    </IconButton>
                </div>
            </div>
        </Card>
    )
}

export default RestaurantCard