import LineVertical from "../../Storage/line-vertical.png";
import Logo from "../../Storage/logo.png";
import './Footer.css';


export default function Footer(){
    return(
            <div className='bg-slate-50 flex justify-center items-center px-6'>
                <div className='w-[350px] sm:w-[520px] md:w[700px] lg:w-[800px] 2xl:w-[1200px] flex justify-between items-center py-[50px]'>
                
                    <div className='w-[180px] sm:w-[280px] md:w-[320px] lg:w-[380px] 2xl: justify-start flex flex-col  '>

                        <p className="text-[18px] font-logo-font text-primary lg:text-[35px]">Social Media</p>
                        <div className="flex gap-[20px] pt-[20px]">
                            <a href="https://www.facebook.com/people/Halal-Yum/61556234113921/" target="_blank">
                                <div>
                                <i className="fa-brands fa-facebook text-[#005eff] text-[40px] transition:translate duration-300 ease-in-out hover:scale-125"></i>
                                </div>
                            </a>
                            <a href="https://www.instagram.com/halalyuminc/" target="_blank">
                                <i className="fa-brands fa-square-instagram text-[#ff0a3b] text-[40px] transition-transform duration-300 hover:scale-125"></i>
                            </a>
                            </div>

                        <div>
                            <p className="text-[12px] lg:text-[20px] text-black font-description-font mt-[50px]">Copyright © 2024 Halal Yum - All Rights Reserved.</p>
                        </div>
                    </div>

                    <div className='w-[140px]  sm:w-[200px] md:w-[240px] lg:w-[300px] flex justify-between '>
                        <img src={LineVertical} alt="" className=" w-[10px] "/>
                        <div className="ml-[10px] ">
                            <div className="text-primary flex items-center">
                                <img src={Logo} alt="" className="w-[50px]"/>
                                <p className="text-[16px] font-logo-font text-primary lg:text-3xl xl:text-4xl">Halal Yum</p>
                            </div>
                            <p className="text-[14px] font-logo-font text-primary lg:text-2xl mt-[5px]">Where Flavour Meets Quality</p>
                        </div>
                    </div>
                
                </div>
            </div>
    )
}