import { Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createCategory } from '../../../State/Restaurant/Action';

const CreateFoodCategoryForm = () => {
    const { restaurant } = useSelector(store => store);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({ foodCategoryName: "", restaurantId: "" });
    const handleSubmit = (e) => {
        e.preventDefault()
        const data = {
            name: formData.foodCategoryName,
            restaurantId: {
                id: restaurant.restaurants[0]?.id
            }
        }
        console.log(data)
        dispatch(createCategory({ requestData: data, jwt: localStorage.getItem("jwt") }))
    }
    const handleInputChange = (event) => {
        const { name, value } = event.target
        setFormData({
            ...formData, [name]: value
        })
    }
    return (
        <div className=''>
            <div className='p-5'>
                <h1 className='text-gray-400 text-center text-xl pb-10'>Create Food Category</h1>
                <form className='space-y-4' onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        id='foodCategoryName'
                        name='foodCategoryName'
                        label='Food Category Name'
                        variant='outlined'
                        onChange={handleInputChange}
                        value={formData.foodCategoryName}>
                    </TextField>
                    <Button variant='contained' type='submit'>Create Food Category</Button>
                </form>
            </div>

        </div>
    )
}

export default CreateFoodCategoryForm