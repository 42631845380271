import { Box, Button, Card, CardHeader, IconButton, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create';
import React, { useEffect } from 'react'
import CreateIngredientForm from './CreateIngredientForm';
import { useDispatch, useSelector } from 'react-redux';
import { deleteIngredient, getIngredientsOfRestaurant, updateStockOfIngredient } from '../../../State/Ingredients/Action';
import AreYouSureModal from '../../modal/AreYouSureModal';
import { useParams } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const IngredientsTable = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { ingredients } = useSelector(store => store);
  const jwt = localStorage.getItem("jwt");
  const { restaurantId } = useParams();

  useEffect(() => {
    dispatch(getIngredientsOfRestaurant({ restaurantId: restaurantId, jwt }));
    console.log('ingredients of restaurant are', ingredients);
  }, []);

  const handleUpdateStock = (ingredientId) => {
    dispatch(updateStockOfIngredient(ingredientId, jwt))
  }

  const handleDeleteIngredientItem = (ingredientId) => {
    dispatch(deleteIngredient({ ingredientId, jwt }))
  }

  return (
    <Box>
      <Card className='mt-1'>
        <CardHeader action={
          <IconButton onClick={handleOpen} aria-label="settings"> <CreateIcon></CreateIcon></IconButton>}
          title='Ingredients'
          sx={{ pt: 2, alignItems: "center" }} />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">ID</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Category</TableCell>
                <TableCell align="left">Availability</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ingredients.ingredients.map((ingredient) => (
                <TableRow key={ingredient.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="left">{ingredient.id}</TableCell>
                  <TableCell align="left">{ingredient.name}</TableCell>
                  <TableCell align="left">{ingredient.category.name}</TableCell>
                  <TableCell align="left"><Button size='large' variant='contained'
                    color={ingredient.inStock ? 'greenColor' : 'error'}
                    onClick={() => handleUpdateStock(ingredient.id)}>{ingredient.inStock ? "IN_STOCK" : "OUT_OF_STOCK"}
                  </Button>
                  </TableCell>
                  <TableCell align="right"><AreYouSureModal onAgreeClick={() => handleDeleteIngredientItem(ingredient.id)} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CreateIngredientForm />
        </Box>
      </Modal>
    </Box>
  )
}

export default IngredientsTable