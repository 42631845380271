const { createTheme } = require("@mui/material");

export const darkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#c19977"
        },
        secondary: {
            main: "#5A20CB"
        },
        black: {
            main: "#242B2E"
        },
        background:{
            main:"#FFFFFF",
            default:"#0D0D0D",
            paper:"#0D0D0D"
        },
        textColor:{
            main:"#111111"
        },
        greenColor:{
            main:"#008000"
        }
    }
})

export const  theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Customize primary color (default blue)
      contrastText: '#ffffff', // Text color on primary backgrounds
    },
    secondary: {
      main: '#ff4081', // Customize secondary color (default pink)
      contrastText: '#000000', // Text color on secondary backgrounds
    },
    black:{
      main:"#242B2E"
    },
    background: {
      main:"#FFFFFF",
      default: '#f4f4f4', // Background color
      paper: '#ffffff', // Color of paper elements like cards
    },
    text: {
      primary: '#333333', // Primary text color
      secondary: '#666666', // Secondary text color
    },
    textColor:{
      main:"#111111"
  },
  greenColor:{
    main:"#008000"
}
  },
  typography: {
    fontFamily: "'Roboto', sans-serif", // Custom font
  },
});