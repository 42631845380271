import { api } from "../../component/config/api";
import { GET_PENDING_RESTAURANTS_ORDER_FAILURE, GET_PENDING_RESTAURANTS_ORDER_REQUEST, GET_PENDING_RESTAURANTS_ORDER_SUCCESS, GET_RESTAURANTS_ORDER_FAILURE, GET_RESTAURANTS_ORDER_REQUEST, GET_RESTAURANTS_ORDER_SUCCESS, UPDATE_ORDER_STATUS_FAILURE, UPDATE_ORDER_STATUS_REQUEST, UPDATE_ORDER_STATUS_SUCCESS } from "./ActionTypes"

export const updateOrderStatus = ({ orderId, orderStatus, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_ORDER_STATUS_REQUEST })
        try {
            const response = await api.put(`/api/admin/order/${orderId}/${orderStatus}`, {}, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            });
            dispatch({ type: UPDATE_ORDER_STATUS_SUCCESS, payload: response.data });
            console.log('updated order status', response.data);
        } catch (error) {
            console.log('error updating order status', error);
            dispatch({ type: UPDATE_ORDER_STATUS_FAILURE, payload: error });
        }
    }
}

export const fetchRestaurantOrders = ({ restaurantId, orderStatus, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: GET_RESTAURANTS_ORDER_REQUEST })
        try {
            const response = await api.get(`/api/admin/order/restaurant/${restaurantId}`, {
                params: {
                    orderStatus: orderStatus
                },
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            });
            dispatch({ type: GET_RESTAURANTS_ORDER_SUCCESS, payload: response.data });
            console.log('fetched restaurant orders successfully', response.data)
        } catch (error) {
            console.log('error fetching restaurant orders', error);
            dispatch({ type: GET_RESTAURANTS_ORDER_FAILURE, payload: error });
        }
    }
}

export const fetchPendingRestaurantOrders = ({ restaurantId, orderStatus, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: GET_PENDING_RESTAURANTS_ORDER_REQUEST })
        try {
            const response = await api.get(`/api/admin/order/restaurant/${restaurantId}`, {
                params: {
                    orderStatus: orderStatus
                },
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            });
            dispatch({ type: GET_PENDING_RESTAURANTS_ORDER_SUCCESS, payload: response.data });
            console.log('fetched restaurant orders successfully', response.data)
        } catch (error) {
            console.log('error fetching restaurant orders', error);
            dispatch({ type: GET_PENDING_RESTAURANTS_ORDER_FAILURE, payload: error });
        }
    }
}