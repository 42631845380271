import { LOGOUT } from "../Authentication/ActionTypes"
import { ADD_ITEM_TO_CART_SUCCESS, CLEAR_CART_SUCCESS, FIND_CART_FAILURE, FIND_CART_REQUEST, FIND_CART_SUCCESS, GET_ALL_CART_ITEMS_REQUEST, REMOVE_CART_ITEM_FAILURE, REMOVE_CART_ITEM_REQUEST, REMOVE_CART_ITEM_SUCCESS, UPDATE_CART_ITEM_FAILURE, UPDATE_CART_ITEM_REQUEST, UPDATE_CART_ITEM_SUCCESS } from "./ActionTypes"

const initialState = {
    cart: null,
    cartItems: [],
    loading: false,
    error: null
}

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case FIND_CART_REQUEST:
        case GET_ALL_CART_ITEMS_REQUEST:
        case UPDATE_CART_ITEM_REQUEST:
        case REMOVE_CART_ITEM_REQUEST:
            return {
                ...state, loading: true, error: null
            }
        case FIND_CART_SUCCESS:
            return {
                ...state, loading: false, cart: action.payload, cartItems: action.payload.items
            }
        case CLEAR_CART_SUCCESS:
            return {
                ...state, loading: false, cart: null, cartItems: []
            }
        case ADD_ITEM_TO_CART_SUCCESS:
            return {
                ...state, loading: false, cartItems: [...state.cartItems, action.payload], cart: action.payload.cart
            }
        case UPDATE_CART_ITEM_SUCCESS:
            return {
                ...state, loading: false, cartItems: state.cartItems.map((cartItem) => {
                    return cartItem.id === action.payload.cartItem.id ? action.payload.cartItem : cartItem
                }), cart: action.payload.cart
            }
        case REMOVE_CART_ITEM_SUCCESS:
            return {
                ...state, loading: false, cartItems: state.cartItems.filter((item) => {
                    return item.id !== action.payload
                }), cart: action.payload.cart
            }
        case FIND_CART_FAILURE:
        case UPDATE_CART_ITEM_FAILURE:
        case REMOVE_CART_ITEM_FAILURE:
            return {
                ...state, loading: false, error: action.payload
            }
        case LOGOUT:
            localStorage.removeItem("jwt");
            return { ...state, cartItems: [], cart: null, success: "logout success" }
        default:
            return state;
    }
}

export default cartReducer;