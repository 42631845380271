import { Box, Button, TextField, Typography } from '@mui/material'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { savePassword } from '../../State/Authentication/Action';
import * as Yup from 'yup'

const initialValues = {
    email: "",
    token: "",
    password: ""
}

const validationSchema = Yup.object().shape({
    password: Yup.string().required("Please Enter a password")
        .min(8, 'Password is too short'),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    p: 4,
};

const SavePassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { auth } = useSelector(store => store);
    const { token } = useParams();
    const handleSubmit = (values) => {
        const userData = { ...values, token }
        dispatch(savePassword({ userData: userData, navigate }))
    }
    return (
        <Box style={style}>
            <Typography variant='h5' className='text-center'>
                Save New Password
            </Typography>
            <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
                <Form>
                    <Field as={TextField}
                        name="password"
                        label="Password"
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        type="password"
                    /><ErrorMessage name="password" component="div" className="error" />
                    <Field as={TextField}
                        name="passwordConfirmation"
                        label="Password Confirmation"
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        type="password"
                    /><ErrorMessage name="passwordConfirmation" component="div" className="error" />
                    <Button fullWidth type='submit' variant='contained' sx={{ mt: 2, padding: "1rem" }}>Save New Password</Button>
                </Form>
            </Formik>
            <Typography variant='body2' align='center' sx={{ mt: 3 }}>
                Don't have an account?
                <Button size='small' onClick={() => navigate("/account/register")}>
                    Register
                </Button>
            </Typography>
            <div className='text-center text-red-500 mt-5'>{auth?.error?.response?.data?.error}</div>
            <div className='text-center text-green-500 mt-5'>{auth?.success}</div>
        </Box>
    )
}

export default SavePassword