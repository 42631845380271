import { toast } from "react-toastify";
import { api } from "../../component/config/api";
import {
    ADD_ITEM_TO_CART_FAILURE, ADD_ITEM_TO_CART_REQUEST,
    ADD_ITEM_TO_CART_SUCCESS, ADD_ITEM_TO_GUEST_CART_REQUEST, ADD_ITEM_TO_GUEST_CART_SUCCESS, CLEAR_CART_FAILURE, CLEAR_CART_REQUEST,
    CLEAR_CART_SUCCESS, CLEAR_GUEST_CART_REQUEST, CLEAR_GUEST_CART_SUCCESS, FIND_CART_FAILURE, FIND_CART_REQUEST, FIND_CART_SUCCESS,
    GET_ALL_CART_ITEMS_FAILURE, GET_ALL_CART_ITEMS_REQUEST, GET_ALL_CART_ITEMS_SUCCESS,
    REMOVE_CART_ITEM_FAILURE, REMOVE_CART_ITEM_REQUEST, REMOVE_CART_ITEM_SUCCESS, REMOVE_GUEST_CART_ITEM_REQUEST, REMOVE_GUEST_CART_ITEM_SUCCESS, UPDATE_CART_ITEM_FAILURE,
    UPDATE_CART_ITEM_REQUEST, UPDATE_CART_ITEM_SUCCESS,
    UPDATE_GUEST_CART_ITEM_REQUEST,
    UPDATE_GUEST_CART_ITEM_SUCCESS
} from "./ActionTypes"

// export const findCart = (token) => {
//     return async (dispatch) => {
//         dispatch({ type: FIND_CART_REQUEST });
//         try {
//             const response = await api.get(`/api/cart`, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             });
//             dispatch({ type: FIND_CART_SUCCESS, payload: response.data });
//             console.log('cart fetched with data:', response.data)
//         } catch (error) {
//             console.log('error finding cart', error);
//             dispatch({ type: FIND_CART_FAILURE, payload: error });
//         }
//     }
// }

// export const getAllCartItems = ({ requestData }) => {
//     return async (dispatch) => {
//         dispatch({ type: GET_ALL_CART_ITEMS_REQUEST });
//         try {
//             const response = await api.get(`/api/carts/${requestData.cartId}/items`, {
//                 headers: {
//                     Authorization: `Bearer ${requestData.token}`,
//                 },
//             });
//             dispatch({ type: GET_ALL_CART_ITEMS_SUCCESS, payload: response.data });
//         } catch (error) {
//             console.log('error getting all cart items', error);
//             dispatch({ type: GET_ALL_CART_ITEMS_FAILURE, payload: error });
//         }
//     }
// }

export const clearGuestCart = () => {
    return async (dispatch) => {
        dispatch({ type: CLEAR_GUEST_CART_REQUEST });
        localStorage.removeItem('cart');
        localStorage.removeItem('guestCartTotal')
        dispatch({type: CLEAR_GUEST_CART_REQUEST});
        dispatch({type: CLEAR_GUEST_CART_SUCCESS});
    }
}

export const addItemToGuestCart = (requestData) => {
    return async (dispatch) => {
        const cart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
        cart.push(requestData);
        localStorage.setItem('cart', JSON.stringify(cart));
        const cartTotal = cart.reduce((accumulator, current) => accumulator + current.totalPrice, 0);
        localStorage.setItem('guestCartTotal', cartTotal);
        const guestCart = {
            total : cartTotal
        }
        dispatch({type: ADD_ITEM_TO_GUEST_CART_REQUEST, payload: {requestData, guestCart}});
        dispatch({type: ADD_ITEM_TO_GUEST_CART_SUCCESS, payload: {requestData, guestCart}});
    }
}

export const updateGuestCartItem = ({ data }) => {
    return async (dispatch) => {
        const cart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];

        const updatedCart = cart.map((cartItem) => {
            return cartItem.id == data.id ? data : cartItem;
        })
        const cartTotal = updatedCart.reduce((accumulator, current) => accumulator + current.totalPrice, 0);
        localStorage.setItem('guestCartTotal', cartTotal);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        const guestCart = {
            total : cartTotal
        }
        dispatch({type: UPDATE_GUEST_CART_ITEM_REQUEST, payload: {data, guestCart}});
        dispatch({type: UPDATE_GUEST_CART_ITEM_SUCCESS, payload: {data, guestCart}});
    }
}

export const removeGuestCartItem = ({ cartItemId }) => {
    return async (dispatch) => {
        const cart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
        const updatedCart = cart.filter((cartItem) => {
            return cartItemId != cartItem.id;
        });
        const cartTotal = updatedCart.reduce((accumulator, current) => accumulator + current.totalPrice, 0);
        localStorage.setItem('guestCartTotal', cartTotal);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        const guestCart = {
            total : cartTotal
        }
        dispatch({type: REMOVE_GUEST_CART_ITEM_REQUEST, payload: {cartItemId, guestCart}});
        dispatch({type: REMOVE_GUEST_CART_ITEM_SUCCESS, payload: {cartItemId, guestCart}});
    }
}