import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { registerUser } from '../../State/Authentication/Action'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

const initialValues = {
    fullName: "",
    email: "",
    password: "",
    role: "ROLE_CUSTOMER"
}


const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    password: Yup.string().required("Please Enter a password")
        .min(8, 'Password is too short'),
    fullName: Yup.string().required('Full Name is required')
});

const RegisterForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { auth } = useSelector(store => store);
    const handleSubmit = (values) => {
        dispatch(registerUser({ userData: values, navigate }))
    }
  return (
    <div className=''>
    <Typography variant='h5' className='text-center'>
        Register
    </Typography>
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        <Form>
        <Field as={TextField}
                        name="fullName"
                        label="Full Name"
                        fullWidth
                        variant="outlined"
                        margin="normal"
                    /><ErrorMessage name="fullName" component="div" className="error" />
                    <Field as={TextField}
                        name="email"
                        label="Email"
                        fullWidth
                        variant="outlined"
                        margin="normal"
                    /><ErrorMessage name="email" component="div" className="error" />
                    <Field as={TextField}
                        name="password"
                        label="Password"
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        type="password"
                        /><ErrorMessage name="password" component="div" className="error" />
            {/* <FormControl fullWidth margin='normal'>
                <InputLabel id="Role-simple-select-label">Role</InputLabel>
                    <Field
                        as={Select}
                        label="Role"
                        name="role">
                        <MenuItem value={"ROLE_CUSTOMER"}>Customer</MenuItem>
                    </Field>
            </FormControl> */}
                        <Button fullWidth type='submit' variant='contained' sx={{mt:2, padding:"1rem"}}>Register</Button>        
        </Form>
    </Formik>
    <Typography variant='body2' align='center' sx={{mt:3}}>
        If you already have an account?
        <Button size='small' onClick={() => navigate("/account/login")}>
            Login
        </Button>
    </Typography>
    <div className='text-center text-red-500 mt-5'>{auth?.error?.response?.data?.error}</div>
</div>
  )
}

export default RegisterForm