import { CREATE_INGREDIENT_CATEGORY_SUCCESS, CREATE_INGREDIENT_SUCCESS, DELETE_INGREDIENT_CATEGORY_FAILURE, DELETE_INGREDIENT_CATEGORY_REQUEST, DELETE_INGREDIENT_CATEGORY_SUCCESS, DELETE_INGREDIENT_FAILURE, DELETE_INGREDIENT_REQUEST, DELETE_INGREDIENT_SUCCESS, GET_INGREDIENTS_REQUEST, GET_INGREDIENTS_SUCCESS, GET_INGREDIENT_CATEGORY_REQUEST, GET_INGREDIENT_CATEGORY_SUCCESS, UPDATE_STOCK_REQUEST, UPDATE_STOCK_SUCCESS } from "./ActionTypes"

const initialState = {
    ingredients: [],
    update: null,
    category: [],
    loading: false,
    error: null
}

export const ingredientReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INGREDIENTS_REQUEST:
        case GET_INGREDIENT_CATEGORY_REQUEST:
        case UPDATE_STOCK_REQUEST:
        case DELETE_INGREDIENT_REQUEST:
        case DELETE_INGREDIENT_CATEGORY_REQUEST:
            return {
                ...state, loading: true, error: null
            }
        case GET_INGREDIENTS_SUCCESS:
            return {
                ...state, ingredients: action.payload, loading: false
            }
        case GET_INGREDIENT_CATEGORY_SUCCESS:
            return {
                ...state, category: action.payload, loading: false
            }
        case CREATE_INGREDIENT_CATEGORY_SUCCESS:
            return {
                ...state, category: [...state.category, action.payload], loading: false
            }
        case DELETE_INGREDIENT_SUCCESS:
            return {
                ...state, ingredients: state.ingredients.filter((ingredient) => ingredient.id !== action.payload)
            }
        case DELETE_INGREDIENT_CATEGORY_SUCCESS:
            return {
                ...state, category: state.category.filter((ingredientCategory) => ingredientCategory.id !== action.payload)
            }
        case CREATE_INGREDIENT_SUCCESS:
            return {
                ...state, ingredients: [...state.ingredients, action.payload], loading: false
            }
        case UPDATE_STOCK_SUCCESS:
            return {
                ...state, update: action.payload, ingredients: state.ingredients.map((item) => {
                    return item.id === action.payload.id ? action.payload : item
                })
            }
        case DELETE_INGREDIENT_FAILURE:
        case DELETE_INGREDIENT_CATEGORY_FAILURE:
            return {
                ...state, loading: false, error: action.payload
            }
        default:
            return state;

    }
}