export const categorizeIngredients = (ingredients) => {
    return ingredients.reduce((accumulator, ingredient) => {
        const { category } = ingredient;
        if (!accumulator[category.name]) {
            accumulator[category.name] = [];
        }
        accumulator[category.name].push(ingredient);
        return accumulator;
    }, {})
}

export const categorizePricesSizes = (pricesSizes) => {
    return pricesSizes.reduce((accumulator, priceSize) => {
        const { priceSizeCategory } = priceSize;
        if (!accumulator[priceSizeCategory.priceSizeCategoryName]) {
            accumulator[priceSizeCategory.priceSizeCategoryName] = [];
        }
        accumulator[priceSizeCategory.priceSizeCategoryName].push(priceSize);
        return accumulator;
    }, {})
}