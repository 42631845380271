import { Button, Card, CardContent, CardHeader, Grid } from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateRestaurantStatus } from '../../../State/Restaurant/Action';
import { useParams } from 'react-router-dom';

const RestaurantDetails = () => {
  const { restaurant } = useSelector(store => store);
  const dispatch = useDispatch();
  const { restaurantId } = useParams();

  console.log('restaurant details', restaurant)
  const handleRestaurantStatus = () => {
    dispatch(updateRestaurantStatus({
      restaurantId: restaurantId, jwt: localStorage.getItem("jwt")
    }))
  }
  return (
    <div className='lg:px-20 px-5 pb-10 pt-20'>
      <div className='py-5 flex justify-center items-center gap-5'>
        <h1 className='text-2xl lg:text-7xl text-center font-bold py-5'>{restaurant.userRestaurants?.name}</h1>
        <div>
          <Button
            onClick={handleRestaurantStatus}
            size='large' variant='contained'
            className='py-[1rem] px-[2rem]'
            color={!restaurant.restaurants[0]?.open ? 'primary' : 'error'}>
            {restaurant.restaurants[0]?.open ? "close" : "open"}
          </Button>
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={<span className='text-black'>Restaurant</span>}>
            </CardHeader>
            <CardContent>
              <div className='space-y-4 text-gray-200'>
                <div className='flex'>
                  <p className='w-48 text-black'>Owner</p>
                  <p className='text-gray-400'><span className='pr-5'>-</span>
                    {restaurant.restaurants[0]?.owner.fullName}
                  </p>
                </div>
                <div className='flex'>
                  <p className='w-48 text-black'>Restaurant Name</p>
                  <p className='text-gray-400'><span className='pr-5'>-</span>
                    {restaurant.restaurants[0]?.name}
                  </p>
                </div>
                <div className='flex'>
                  <p className='w-48 text-black'>Cuisine Type</p>
                  <p className='text-gray-400'><span className='pr-5'>-</span>
                    {restaurant.restaurants[0]?.cuisineType}
                  </p>
                </div>
                <div className='flex'>
                  <p className='w-48 text-black'>Opening Hours</p>
                  <p className='text-gray-400'><span className='pr-5'>-</span>
                    {restaurant.restaurants[0]?.openingHours}
                  </p>
                </div>
                <div className='flex'>
                  <p className='w-48 text-black'>Status</p>
                  <p className='text-gray-400'>
                    <span className='pr-5'>-</span>
                    {restaurant.restaurants[0]?.open ? <span className='px-5 py-2 rounded-full bg-green-400 text-gray-950'>OPEN</span> :
                      <span className='px-5 py-2 rounded-full bg-red-400 text-gray-50'>CLOSED</span>}
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader title={<span className='text-black'>Address</span>}>
            </CardHeader>
            <CardContent>
              <div className='space-y-4 text-gray-200'>
                <div className='flex'>
                  <p className='w-48 text-black'>Country</p>
                  <p className='text-gray-400'><span className='pr-5'>-</span>
                    Code with FaiS
                  </p>
                </div>
                <div className='flex'>
                  <p className='w-48 text-black'>City</p>
                  <p className='text-gray-400'><span className='pr-5'>-</span>
                    HALAL YUM 1
                  </p>
                </div>
                <div className='flex'>
                  <p className='w-48 text-black'>Postal Code</p>
                  <p className='text-gray-400'><span className='pr-5'>-</span>
                    Halal Desi
                  </p>
                </div>
                <div className='flex'>
                  <p className='w-48 text-black'>Street Address</p>
                  <p className='text-gray-400'><span className='pr-5'>-</span>
                    10:00AM - 10:00PM
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader title={<span className='text-black'>Contact</span>}>
            </CardHeader>
            <CardContent>
              <div className='space-y-4 text-gray-200'>
                <div className='flex'>
                  <p className='w-48 text-black'>Email</p>
                  <p className='text-gray-400'><span className='pr-5'>-</span>
                    {restaurant.restaurants[0]?.contactInformation.email}
                  </p>
                </div>
                <div className='flex'>
                  <p className='w-48 text-black'>Mobile</p>
                  <p className='text-gray-400'><span className='pr-5'>-</span>
                    {restaurant.restaurants[0]?.contactInformation.mobile}
                  </p>
                </div>
                <div className='flex'>
                  <p className='w-48 text-black'>Social</p>
                  <div className='flex text-gray-400 items-center pb-3 gap-2'>
                    <span className='pr-5'>-</span>
                    <a href={restaurant.restaurants[0]?.contactInformation.instagram}>
                      <InstagramIcon sx={{ fontSize: "3rem" }}></InstagramIcon>
                    </a>
                    <a href={restaurant.restaurants[0]?.contactInformation.facebook}>
                      <FacebookIcon sx={{ fontSize: "3rem" }}></FacebookIcon>
                    </a>
                    <a href={restaurant.restaurants[0]?.contactInformation.twitter}>
                      <TwitterIcon sx={{ fontSize: "3rem" }}></TwitterIcon>
                    </a>
                    <a href={restaurant.restaurants[0]?.contactInformation.linkedin}>
                      <LinkedInIcon sx={{ fontSize: "3rem" }}></LinkedInIcon>
                    </a>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default RestaurantDetails