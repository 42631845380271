import { Box, Card, CardHeader, IconButton, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AreYouSureModal from '../../modal/AreYouSureModal';
import { deletePriceSizeCategory, getPriceSizeCategory } from '../../../State/pricesSizes/Action';
import CreatePriceSizeCategoryForm from './CreatePriceSizeCategoryForm';
import { useParams } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const PriceSizesCategoryTable = () => {
  const { pricesSizes } = useSelector(store => store);
  console.log('prices Sizes from store', pricesSizes);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const jwt = localStorage.getItem("jwt");
  const { restaurantId } = useParams();

  useEffect(() => {
    dispatch(getPriceSizeCategory({ restaurantId: restaurantId, jwt }));
  }, [])

  const handleDeletePriceSizeCategory = (priceSizeCategoryId) => {
    dispatch(deletePriceSizeCategory({ priceSizeCategoryId, jwt }))
  }

  return (
    <Box>
      <Card className='mt-1'>
        <CardHeader action={
          <IconButton onClick={handleOpen} aria-label="settings"> <CreateIcon></CreateIcon></IconButton>}
          title='Price Size Category'
          sx={{ pt: 2, alignItems: "center" }} />
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">ID</TableCell>
                <TableCell align="left">Price Size Category Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pricesSizes.priceSizeCategory.map((priceSizeCategory) => (
                <TableRow key={priceSizeCategory.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="left">{priceSizeCategory.id}</TableCell>
                  <TableCell align="left">{priceSizeCategory.priceSizeCategoryName}</TableCell>
                  <TableCell align="left"><AreYouSureModal onAgreeClick={() => handleDeletePriceSizeCategory(priceSizeCategory.id)} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <CreatePriceSizeCategoryForm />
        </Box>
      </Modal>
    </Box>
  )
}

export default PriceSizesCategoryTable