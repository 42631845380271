import { Box, Divider, Grid, Modal, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CartItem from './CartItem'
import { Button } from '@mui/material';
// import * as Yup from "yup"
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { createGuestOrder, createOrder } from '../../State/Order/Action';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Stripe from "react-stripe-checkout";
import axios from 'axios';
import { clearCart } from '../../State/Cart/Action';
import { MyPlaceAutoComplete } from '../Home/MyPlaceAutoComplete';
import { BASE_API_URL } from '../config/api';
import { Navbar } from '../Navbar/Navbar';
import { clearGuestCart } from '../../State/GuestCart/Action';



export const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    outline: 'none',
    boxShadow: 24,
    p: 4,
};

const initialValues = {
    streetAddress: '',
    state: '',
    pincode: '',
    city: ''
}

//   const validationSchema = Yup.object.shape({
//     streetAddress: Yup.string().required("Street Address is required"),
//     state: Yup.string().required("State is required"),
//     pincode: Yup.required("Pin Code is required"),
//     city: Yup.string().required("City is required")
//   })

const Cart = () => {
    const { cart, guestCart, restaurant } = useSelector(store => store);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const [totalAsItIs, setTotalAsItIs] = useState(0);
    const [tax, setTax] = useState(0);
    const [totalWithTax, setTotalWithTax] = useState(0);
    const [deliveryFee, setDeliveryFee] = useState(0);
    const [selfPickUp, setSelfPickUp] = useState(false);
    const [userAddress, setUserAddress] = useState("")
    const [eligibleForDelivery, setEligibleForDelivery] = useState(false);
    const jwt = localStorage.getItem("jwt");

    useEffect(() => {
        console.log('printing cart total----------', cart?.cart?.total);
        setUserAddress(localStorage.getItem("user_address"))
        setEligibleForDelivery(JSON.parse(localStorage.getItem("eligibleForDelivery")))
        setTotalAsItIs(+Number(jwt ? (cart?.cart?.total ? cart?.cart?.total : 0) : (guestCart?.cart?.total ? guestCart?.cart?.total : 0)).toFixed(2))
        setTax(+Number((jwt ? (cart?.cart?.total ? cart?.cart?.total : 0) : (guestCart?.cart?.total ? guestCart?.cart?.total : 0)) * 0.13).toFixed(2))
        setTotalWithTax(+Number((jwt ? (cart?.cart?.total ? cart?.cart?.total : 0) : (guestCart?.cart?.total ? guestCart?.cart?.total : 0)) + tax).toFixed(2))
        setDeliveryFee(5);
    }, [userAddress, cart?.cart?.total, guestCart?.cart?.total, tax, eligibleForDelivery])

    const toggleSelfPickUp = () => {
        setSelfPickUp(!selfPickUp);
    }

    const confirmAddress = () => {
        setUserAddress(localStorage.getItem("user_address"));
        setEligibleForDelivery(JSON.parse(localStorage.getItem("eligibleForDelivery")))
        setSelfPickUp(false)
    }

    const handleSubmit = async (token) => {
        if(jwt) {
            console.log(token);
            await axios.post(`${BASE_API_URL}/api/payment/charge`, "", {
                headers: {
                    token: token.id,
                    amount: selfPickUp ? totalWithTax : totalWithTax + deliveryFee,
                    Authorization: `Bearer ${localStorage.getItem("jwt")}`
                },
            }).then((response) => {
                console.log('response coming back from backend', response)
                if (response.data.response === 'succeeded') {
                    const data = {
                        jwt: localStorage.getItem("jwt"),
                        order: {
                            restaurantId: cart?.cart?.items[0]?.food?.restaurant.id,
                            totalPrice: selfPickUp ? totalWithTax : totalWithTax + 5,
                            selfPickUp: selfPickUp,
                            userAddress: userAddress
                            // deliveryAddress: {
                            //     fullName: auth.user?.fullName,
                            //     streetAddress: values.streetAddress,
                            //     city: values.city,
                            //     state: values.state,
                            //     postalCode: values.pincode,
                            //     country: "CANADA"
                            // }
                        }
                    }
                    dispatch(createOrder(data));
                    dispatch(clearCart())
                    navigate('/payment/success');
                }
            }).catch((error) => {
                console.log(error)
                alert(error);
            });
        } else {
            console.log(token);
            await axios.post(`${BASE_API_URL}/api/payment/guest/charge`, "", {
                headers: {
                    token: token.id,
                    amount: selfPickUp ? totalWithTax : totalWithTax + deliveryFee,
                },
            }).then((response) => {
                console.log('response coming back from backend', response)
                if (response.data.response === 'succeeded') {
                    const data = {
                        order: {
                            restaurantId: restaurant?.restaurants[0]?.id,
                            totalPrice: selfPickUp ? totalWithTax : totalWithTax + 5,
                            selfPickUp: selfPickUp,
                            userAddress: userAddress,
                            guestCartItems: guestCart?.cartItems
                        }
                    }
                    dispatch(createGuestOrder(data));
                    dispatch(clearGuestCart())
                    navigate('/payment/success');
                }
            }).catch((error) => {
                console.log(error)
                alert(error);
            });
        }

    }
    const handleNavigateToRestaurant = () => {
        if (restaurant?.restaurants[0]?.open) {
            navigate(`/restaurant/${restaurant?.restaurants[0]?.address.city}/${restaurant?.restaurants[0]?.name}/${restaurant?.restaurants[0]?.id}`)
        }
    }


  return (
    <>
        <Navbar/>
        <main className='lg:flex justify-between '>
            <section className='lg:w-[30%] space-y-6 lg:min-h-screen pt-10'>
            {(cart?.cart?.items ? cart?.cart?.items  : guestCart.cartItems).map((cartItem) =><CartItem key={cartItem.id} cartItem={cartItem}/>) }

            <Divider/>
            <div className='billDetails px-5 text-sm'>
                <p className='font-semibold py-5 text-black'>Bill Details</p>
                <div className='space-y-3'>
                    <div className='flex justify-between text-black'>
                        <p>Item Total</p>
                        <p>${totalAsItIs}</p>
                    </div>
                    <div className='flex justify-between text-black'>
                        <p>Tax</p>
                        <p>${tax}</p>
                    </div>
                    <div className='flex justify-between text-black'>
                        <p>Delivery Fee</p>
                        <p>${selfPickUp ? 0 : userAddress ? deliveryFee : 0}</p>
                    </div>
                    <Divider/>
                </div>
                <div className='flex justify-between text-black'>
                    <p>Total Pay</p>
                    <p>${selfPickUp ? totalWithTax : eligibleForDelivery ? (totalWithTax + deliveryFee).toFixed(2) : totalWithTax}</p>
                </div>
                <div className='flex justify-between pt-10 space-x-10'>
                <Button onClick={handleNavigateToRestaurant} variant='contained'>Go to Restaurant</Button>
                {/* <Button disabled={cart?.cart?.items?.length > 0 ? false : true} onClick={handleSubmit} variant='contained'>Check Out</Button> */}
                { (selfPickUp || eligibleForDelivery) && totalWithTax > 0 &&
                <Stripe 
                        stripeKey="pk_test_51P6GfNP04qAWcZ0aS3AmXxKJ9EsJRqi2hSQvwadkFghnepr1KFoqQNd7TKcugDm5IRaAtlFEh568duCbeN7y3l5z00VCM8sAlp"
                        token={handleSubmit}
                />}
                </div>
            </div>
            </section>
            <Divider orientation='vertical' flexItem/>
            {/* <section className='lg:w-[70%] flex justify-center px-5 pb-10 lg:pb-0'>
                <div>
                    <h1 className='text-center font-semibold text-2xl py-10'>
                        Choose Delivery Address
                    </h1>
                    <div className='flex gap-5 flex-wrap justify-center'>
                        {auth.user?.addresses.map((item) => <AddressCart handleSelectAddress={createOrderUsingSelectedAddress} item={item} showButton={true}/>)}
                        <Card className="flex gap-5 w-64 p-5">
                            <AddLocationIcon/>
                                <div className='space-y-3 text-gray-500'>
                                    <h1 className='font-semibold text-lg text-white'>Add New Address</h1>
                                    <Button variant='outlined' fullWidth onClick={handleOpenAddressModal}>Add</Button>
                                </div>
                        </Card>
                    </div>
                </div>
            </section> */}
                <section className='lg:w-[70%] flex justify-center pt-10 px-10 pb-10 lg:pb-0 space-y-5'>
                    <div>
                        <div className='container flex flex-col items-center pt-10 space-y-5'>
                            <MyPlaceAutoComplete />
                            <Button variant='contained' onClick={() => confirmAddress()}>Confirm Address</Button>
                        </div>

                        {!selfPickUp && eligibleForDelivery && <div>
                            <h1 className='text-center font-semibold text-2xl py-10'>
                                Order will be delivered at
                            </h1>
                            <p className='text-center'>
                                {userAddress}
                            </p>
                        </div>}
                        <div className='container flex flex-col pt-10 items-center'>
                            <Button variant='contained' onClick={() => toggleSelfPickUp()} sx={{ backgroundColor: selfPickUp ? "green" : "red" }}>Self Pick up</Button>
                            {selfPickUp && <div>
                                <h1 className='text-center font-semibold text-2xl py-10'>
                                    Pick Up your order at
                                </h1>
                                <p>
                                    3095 Hurontario St, Mississauga, ON L5A 2G9
                                </p>
                            </div>}
                        </div>
                    </div>
                </section>
            </main>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Field as={TextField}
                                        name="streetAddress"
                                        label="Street Address"
                                        fullWidth
                                        variant="outlined"
                                    // error={!ErrorMessage("Street Address")}
                                    // helperText={
                                    //     <ErrorMessage>
                                    //         {(msg) => <span className='text-red-600'>{msg}</span>}
                                    //     </ErrorMessage>
                                    // }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field as={TextField}
                                        name="state"
                                        label="State"
                                        fullWidth
                                        variant="outlined"
                                    // error={!ErrorMessage("Street Address")}
                                    // helperText={
                                    //     <ErrorMessage>
                                    //         {(msg) => <span className='text-red-600'>{msg}</span>}
                                    //     </ErrorMessage>
                                    // }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field as={TextField}
                                        name="city"
                                        label="City"
                                        fullWidth
                                        variant="outlined"
                                    // error={!ErrorMessage("Street Address")}
                                    // helperText={
                                    //     <ErrorMessage>
                                    //         {(msg) => <span className='text-red-600'>{msg}</span>}
                                    //     </ErrorMessage>
                                    // }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field as={TextField}
                                        name="pincode"
                                        label="PinCode"
                                        fullWidth
                                        variant="outlined"
                                    // error={!ErrorMessage("Street Address")}
                                    // helperText={
                                    //     <ErrorMessage>
                                    //         {(msg) => <span className='text-red-600'>{msg}</span>}
                                    //     </ErrorMessage>
                                    // }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button fullWidth variant='contained' type='submit' color='primary'>Deliver Here</Button>
                                </Grid>
                            </Grid>
                        </Form>

                    </Formik>
                </Box>
            </Modal>
            <ToastContainer />
        </>
    )
}

export default Cart