import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createPriceSize } from '../../../State/pricesSizes/Action';

const CreatePriceSizeForm = () => {
    const { restaurant } = useSelector(store => store);
    const dispatch = useDispatch();
    const jwt = localStorage.getItem("jwt");
    const [formData, setFormData] = useState({ priceSizeName: "", priceSizeCategoryId: "" });
    const { pricesSizes } = useSelector(store => store);
    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            size: formData.priceSizeName,
            price: formData.price,
            restaurantId: restaurant?.restaurants[0]?.id,
            priceSizeCategoryId: formData.priceSizeCategoryId
        }
        dispatch(createPriceSize({ requestData: data, jwt }))
        console.log(data)
    }
    const handleInputChange = (event) => {
        const { name, value } = event.target
        setFormData({
            ...formData, [name]: value
        })
    }
    return (
        <div className=''>
            <div className='p-5'>
                <h1 className='text-gray-400 text-center text-xl pb-10'>Create Price Size</h1>
                <form className='space-y-4' onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        id='priceSizeName'
                        name='priceSizeName'
                        label='Price Size Name'
                        variant='outlined'
                        onChange={handleInputChange}
                        value={formData.priceSizeName}>
                    </TextField>
                    <TextField
                        fullWidth
                        id='price'
                        name='price'
                        label='Price'
                        variant='outlined'
                        onChange={handleInputChange}
                        value={formData.price}>
                    </TextField>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Price Size Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formData.priceSizeCategoryId}
                            name="priceSizeCategoryId"
                            label="Price Size Category"
                            onChange={handleInputChange}
                        > {pricesSizes.priceSizeCategory.map((priceSizeCategory) => <MenuItem key={priceSizeCategory.id} value={priceSizeCategory.id}>{priceSizeCategory.priceSizeCategoryName}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <Button variant='contained' type='submit'>Create Price Size</Button>
                </form>
            </div>
        </div>
    )
}

export default CreatePriceSizeForm