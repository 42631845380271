
import Line from "../../Storage/line.png";
import './VisitUs.css';

export default function VisitUs(){

    return(
        <div className='flex justify-center  py-[50px] bg-gradient-to-br from-yellow-50 to-red-50 '>
            <div className='w-[1200px]   items-center '>
                
                <p className=" text-3xl  font-logo-font text-primary font-semibold sm:text-4xl md:text-5xl text-center">Visit Us</p>
                <div className="flex justify-center mb-[100px]">
                    <img src={Line} alt="line" className="w-[300px] h-[20px] -translate-y-1"/>
                </div>


                <div className='flex flex-col gap-[10px] items-center  xl:flex-row xl:justify-around xl:mt-[100px] '>
                    <div className='w-[350px] h-[500px]  p-[20px] sm:w-[600px] md:mb-[20px]'>
                         <div className="mb-[40px]">
                            <p className="text-2xl font-logo-font font-bold text-center text-secondary mb-[7px] md:text-4xl">Special Requests?</p>  
                            <p className="text-[12px] text-black text-center font-description-font md:text-[18px]">Do you have dietary concerns? Questions about an upcoming event? Drop us a line, and we'll get back to you soon.</p>
                         </div>   
                         <div className="my-[40px]">
                            <p className="text-2xl font-logo-font font-bold text-center text-secondary mb-[7px] md:text-4xl">Halal Yum</p>  
                            <p className="text-[12px] text-black text-center font-description-font md:text-[18px]">3095 Hurontario Street, Mississauga, Ontario L5A 2G9, Canada</p>
                            <p className="text-[12px] text-black text-center font-description-font md:text-[18px]">647-910-0986</p>
                         </div>
                         <div className="">
                            <p className="text-2xl font-logo-font font-bold text-center text-secondary mb-[7px] md:text-4xl">Hours</p>  
                            <p className="text-[12px] text-black text-center font-description-font md:text-[18px]">Monday to Thursday: 12:00 AM to 04:00 PM</p>
                            <p className="text-[12px] text-black text-center font-description-font md:text-[18px]">Friday to Saturday: 02:00 AM to 04:00 PM</p>
                            <p className="text-[12px] text-black text-center font-description-font md:text-[18px]">Saturday to Sunday: 09:00 AM to 12:00 AM</p>
                         </div> 
                    </div>
                    <div className='w-[300px] h-[250px]  mt-[10px] sm:w-[500px] sm:h-[400px] flex items-center shadow-xl'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2890.305606052573!2d-79.62477617608872!3d43.57935041593805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b47000747b017%3A0xc9f6b06a1141c027!2sHalal%20Yum!5e0!3m2!1sen!2s!4v1722786802434!5m2!1sen!2s"    frameborder="0" className='rounded-md w-[300px] h-[250px] sm:w-[500px] sm:h-[400px]'></iframe>
                    </div>
                </div>
            </div>
        </div>
    )


}
