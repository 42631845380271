import { IconButton } from '@mui/material'
import React from 'react'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import { removeCartItem, updateCartItem } from '../../State/Cart/Action';
import { removeGuestCartItem, updateGuestCartItem } from '../../State/GuestCart/Action';

const CartItem = ({ cartItem }) => {
    const { auth, cart, guestCart } = useSelector(store => store);
    const dispatch = useDispatch();
    const jwt = localStorage.getItem("jwt");
    const cartItemPrice = Number(cartItem?.totalPrice).toFixed(2);

    const handleUpdateCartItem = (value) => {
        console.log('printing existing cart from store ---', cart);
        if (value === -1 && cartItem.quantity === 1) {
            handleRemoveCartItem()
        } else {
            if (jwt) {
                const data = { cartItemId: cartItem.id, quantity: cartItem.quantity + value, size: cartItem.size }
                dispatch(updateCartItem({ data, jwt: auth.jwt || jwt }))
            } else {
                const data = {
                    id: cartItem.id,
                    quantity: cartItem?.quantity + value,
                    size: cartItem.size,
                    price: cartItem.price,
                    totalPrice: (cartItem?.quantity + value) * cartItem?.price,
                    foodId: cartItem.foodId,
                    food: {
                        images: cartItem?.food?.images,
                        name: cartItem?.food?.name
                    }
                }
                dispatch(updateGuestCartItem({ data }))
            }

        }
    }

    const handleRemoveCartItem = () => {
        if (jwt) {
            dispatch(removeCartItem({ cartItemId: cartItem?.id, jwt: auth.jwt || jwt }))
        } else {
            dispatch(removeGuestCartItem({ cartItemId: cartItem?.id }))
        }
    }

    return (
        <div className='px-5'>
            <div className='lg:flex items-center lg:space-x-5'>
                <div>
                    <img className='w-[5rem] h-[5rem] object-cover'
                        src={cartItem?.food?.images[0]}
                        alt='' />
                </div>
                <div className='flex items-center justify-between lg:w-[70%]'>
                    <div className='space-y-1 lg:space-y-3 w-full'>
                        <p>{cartItem?.food?.name}</p>
                        <div className='flex justify-between items-center'>
                            <div className='flex items-center space-x-1'>
                                <IconButton onClick={() => handleUpdateCartItem(-1)}>
                                    <RemoveCircleOutlineIcon />
                                </IconButton>
                                <div className='w-5 h-5 text-xs flex items-center justify-center'>
                                    {cartItem?.quantity}
                                </div>
                                <IconButton onClick={() => handleUpdateCartItem(1)}>
                                    <AddCircleOutlineIcon />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    <p>${cartItemPrice}</p>
                </div>
            </div>
            <div className='pt-3 space-x-2'>
                {/* {cartItem.ingredients.map((ingredient) => <Chip key={ingredient} label={ingredient}/>)} */}
                {cartItem?.size}
            </div>
        </div>
    )
}

export default CartItem