import React from 'react'
import MenuTable from './MenuTable';

const Menu = () => {
  return (
    <div className='px-2'>
      <MenuTable />
    </div>
  )
}

export default Menu