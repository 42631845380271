import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "../../component/config/api";
import { CREATE_INGREDIENT_CATEGORY_FAILURE, CREATE_INGREDIENT_CATEGORY_REQUEST, CREATE_INGREDIENT_CATEGORY_SUCCESS, CREATE_INGREDIENT_FAILURE, CREATE_INGREDIENT_REQUEST, CREATE_INGREDIENT_SUCCESS, DELETE_INGREDIENT_CATEGORY_FAILURE, DELETE_INGREDIENT_CATEGORY_REQUEST, DELETE_INGREDIENT_CATEGORY_SUCCESS, DELETE_INGREDIENT_FAILURE, DELETE_INGREDIENT_REQUEST, DELETE_INGREDIENT_SUCCESS, GET_INGREDIENTS_FAILURE, GET_INGREDIENTS_REQUEST, GET_INGREDIENTS_SUCCESS, GET_INGREDIENT_CATEGORY_FAILURE, GET_INGREDIENT_CATEGORY_REQUEST, GET_INGREDIENT_CATEGORY_SUCCESS, UPDATE_STOCK_FAILURE, UPDATE_STOCK_REQUEST, UPDATE_STOCK_SUCCESS } from "./ActionTypes";

export const getIngredientsOfRestaurant = ({ restaurantId, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: GET_INGREDIENTS_REQUEST })
        try {
            const response = await api.get(`api/admin/ingredients/restaurant/${restaurantId}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                });
            dispatch({ type: GET_INGREDIENTS_SUCCESS, payload: response.data });
            console.log('successfully fetched ingredients of restaurant', response.data);
        } catch (error) {
            console.log('error getting ingredients of a restaurant', error);
            dispatch({ type: GET_INGREDIENTS_FAILURE, payload: error });

        }
    }
}

export const createIngredient = ({ requestData, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: CREATE_INGREDIENT_REQUEST })
        try {
            const response = await api.post(`api/admin/ingredients/ingredientItem`, requestData,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                });
            dispatch({ type: CREATE_INGREDIENT_SUCCESS, payload: response.data });
            console.log('successfully created ingredient', response.data);
        } catch (error) {
            console.log('error creating ingredient ', error);
            dispatch({ type: CREATE_INGREDIENT_FAILURE, payload: error });

        }
    }
}

export const deleteIngredient = ({ ingredientId, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: DELETE_INGREDIENT_REQUEST });
        try {
            await api.delete(`/api/admin/ingredients/ingredientItem/${ingredientId}`, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            })
            dispatch({ type: DELETE_INGREDIENT_SUCCESS, payload: ingredientId });
            console.log('successfully deleted ingredient item with id:', ingredientId);
            toast.success('Ingredient Deleted Successfully!')
        } catch (error) {
            console.log('error deleting ingredient item', error);
            dispatch({ type: DELETE_INGREDIENT_FAILURE, payload: error });
            toast.warn('Ingredient Might Be In Use')
        }
    }
}

export const createIngredientCategory = ({ requestData, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: CREATE_INGREDIENT_CATEGORY_REQUEST })
        try {
            const response = await api.post(`api/admin/ingredients/category`, requestData,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                });
            dispatch({ type: CREATE_INGREDIENT_CATEGORY_SUCCESS, payload: response.data });
            console.log('successfully created ingredient category', response.data);
        } catch (error) {
            console.log('error creating ingredient category ', error);
            dispatch({ type: CREATE_INGREDIENT_CATEGORY_FAILURE, payload: error });

        }
    }
}

export const deleteIngredientCategory = ({ ingredientCategoryId, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: DELETE_INGREDIENT_CATEGORY_REQUEST });
        try {
            await api.delete(`/api/admin/ingredients/category/${ingredientCategoryId}`, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            })
            dispatch({ type: DELETE_INGREDIENT_CATEGORY_SUCCESS, payload: ingredientCategoryId });
            console.log('successfully deleted ingredient Category', ingredientCategoryId);
            toast.success('Ingredient Category Deleted Successfully!')

        } catch (error) {
            console.log('error deleting ingredient Category', error);
            dispatch({ type: DELETE_INGREDIENT_CATEGORY_FAILURE, payload: error });
            toast.warn('Ingredient Category Might Be In Use')
        }
    }
}

export const getIngredientCategory = ({ restaurantId, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: GET_INGREDIENT_CATEGORY_REQUEST })
        try {
            const response = await api.get(`api/admin/ingredients/restaurant/${restaurantId}/category`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                });
            dispatch({ type: GET_INGREDIENT_CATEGORY_SUCCESS, payload: response.data });
            console.log('successfully fetched ingredient category of restaurant', response.data);
        } catch (error) {
            console.log('error getting ingredient category ', error);
            dispatch({ type: GET_INGREDIENT_CATEGORY_FAILURE, payload: error });

        }
    }
}

export const updateStockOfIngredient = (id, jwt) => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_STOCK_REQUEST })
        try {
            const response = await api.put(`api/admin/ingredients/${id}/stock`, {},
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                });
            dispatch({ type: UPDATE_STOCK_SUCCESS, payload: response.data });
            console.log('successfully updated stock of ingredients of restaurant', response.data);
            toast.success('Ingredient Stock Updated!')
        } catch (error) {
            console.log('error update stock ingredient ', error);
            dispatch({ type: UPDATE_STOCK_FAILURE, payload: error });
            toast.error('Error Updating Ingredient Stock')
        }
    }
}