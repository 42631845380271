import { Box, Modal } from '@mui/material'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { style } from '../Cart/Cart';
import RegisterForm from './RegisterForm';
import LoginForm from './LoginForm';
import ResetPasswordForm from './ResetPasswordForm';

export const Auth = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const handleOnClose = () => {
        navigate("/")
    }
  return (
    <div className='bg-white'>
        <>
    <Modal open={
        location.pathname ==="/account/register" || location.pathname ==="/account/login" || location.pathname ==="/account/resetpassword"
    } onClose={handleOnClose}>
        <Box sx={style}>
            {location.pathname === "/account/register" ? <RegisterForm/> : location.pathname === "/account/login" ? <LoginForm/> : <ResetPasswordForm/>}
        </Box>

    </Modal>
    </>
    </div>
  )
}
