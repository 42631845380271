import { LOGOUT } from "../Authentication/ActionTypes"
import { ADD_ITEM_TO_GUEST_CART_SUCCESS, CLEAR_GUEST_CART_SUCCESS, FIND_GUEST_CART_FAILURE, FIND_GUEST_CART_REQUEST, FIND_GUEST_CART_SUCCESS, GET_ALL_GUEST_CART_ITEMS_REQUEST, REMOVE_GUEST_CART_ITEM_FAILURE, REMOVE_GUEST_CART_ITEM_REQUEST, REMOVE_GUEST_CART_ITEM_SUCCESS, UPDATE_GUEST_CART_ITEM_FAILURE, UPDATE_GUEST_CART_ITEM_REQUEST, UPDATE_GUEST_CART_ITEM_SUCCESS } from "./ActionTypes"

const initialState = {
    cart: { total: 0 },
    cartItems: [],
    loading: false,
    error: null
}

if (localStorage.getItem('cart')) {
    initialState.cartItems = JSON.parse(localStorage.getItem('cart'));
} else {
    initialState.cartItems = [];
}
if (localStorage.getItem('guestCartTotal')) {
    initialState.cart.total = +Number(localStorage.getItem('guestCartTotal'))
} else {
    initialState.cart.total = 0;
}

const guestCartReducer = (state = initialState, action) => {
    switch (action.type) {
        case FIND_GUEST_CART_REQUEST:
        case GET_ALL_GUEST_CART_ITEMS_REQUEST:
        case UPDATE_GUEST_CART_ITEM_REQUEST:
        case REMOVE_GUEST_CART_ITEM_REQUEST:
            return {
                ...state, loading: true, error: null
            }
        case FIND_GUEST_CART_SUCCESS:
            return {
                ...state, loading: false, cart: action.payload, cartItems: action.payload.items
            }
        case CLEAR_GUEST_CART_SUCCESS:
            return {
                ...state, loading: false, cart: null, cartItems: []
            }
        case ADD_ITEM_TO_GUEST_CART_SUCCESS:
            return {
                ...state, loading: false, cartItems: [...state.cartItems, action.payload.requestData], cart:
                {
                    total: +Number(action?.payload?.guestCart?.total),
                }
            }
        case UPDATE_GUEST_CART_ITEM_SUCCESS:
            return {
                ...state, loading: false, cartItems: state.cartItems.map((cartItem) => {
                    return cartItem?.id === action?.payload?.data?.id ? action?.payload?.data : cartItem
                }), cart: action.payload.guestCart
            }
        case REMOVE_GUEST_CART_ITEM_SUCCESS:
            return {
                ...state, loading: false, cartItems: state.cartItems.filter((item) => {
                    return item.id !== action.payload?.cartItemId
                }), cart: action.payload.guestCart
            }
        case FIND_GUEST_CART_FAILURE:
        case UPDATE_GUEST_CART_ITEM_FAILURE:
        case REMOVE_GUEST_CART_ITEM_FAILURE:
            return {
                ...state, loading: false, error: action.payload
            }
        default:
            return state;
    }
}

export default guestCartReducer;