import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "../../component/config/api";
import { CREATE_PRICE_SIZE_CATEGORY_FAILURE, CREATE_PRICE_SIZE_CATEGORY_REQUEST, CREATE_PRICE_SIZE_CATEGORY_SUCCESS, CREATE_PRICE_SIZE_FAILURE, CREATE_PRICE_SIZE_REQUEST, CREATE_PRICE_SIZE_SUCCESS, DELETE_PRICE_SIZE_CATEGORY_FAILURE, DELETE_PRICE_SIZE_CATEGORY_REQUEST, DELETE_PRICE_SIZE_CATEGORY_SUCCESS, DELETE_PRICE_SIZE_FAILURE, DELETE_PRICE_SIZE_REQUEST, DELETE_PRICE_SIZE_SUCCESS, GET_PRICES_SIZES_FAILURE, GET_PRICES_SIZES_REQUEST, GET_PRICES_SIZES_SUCCESS, GET_PRICE_SIZE_CATEGORY_FAILURE, GET_PRICE_SIZE_CATEGORY_REQUEST, GET_PRICE_SIZE_CATEGORY_SUCCESS, UPDATE_PRICE_SIZE_STOCK_FAILURE, UPDATE_PRICE_SIZE_STOCK_REQUEST, UPDATE_PRICE_SIZE_STOCK_SUCCESS } from "./ActionTypes";

export const getPricesSizesOfRestaurant = ({ restaurantId, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: GET_PRICES_SIZES_REQUEST })
        try {
            const response = await api.get(`api/admin/pricessizes/restaurant/${restaurantId}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                });
            dispatch({ type: GET_PRICES_SIZES_SUCCESS, payload: response.data });
            console.log('successfully fetched prices sizes of restaurant', response.data);
        } catch (error) {
            console.log('error getting prices sizes of a restaurant', error);
            dispatch({ type: GET_PRICES_SIZES_FAILURE, payload: error });

        }
    }
}

export const createPriceSize = ({ requestData, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: CREATE_PRICE_SIZE_REQUEST })
        try {
            const response = await api.post(`api/admin/pricessizes/pricesize`, requestData,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                });
            dispatch({ type: CREATE_PRICE_SIZE_SUCCESS, payload: response.data });
            console.log('successfully created price size', response.data);
        } catch (error) {
            console.log('error creating price size ', error);
            dispatch({ type: CREATE_PRICE_SIZE_FAILURE, payload: error });

        }
    }
}

export const deletePriceSize = ({ priceSizeId, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: DELETE_PRICE_SIZE_REQUEST });
        try {
            await api.delete(`/api/admin/pricessizes/pricesize/${priceSizeId}`, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            })
            dispatch({ type: DELETE_PRICE_SIZE_SUCCESS, payload: priceSizeId });
            console.log('successfully deleted price size item with id:', priceSizeId);
            toast.success('Price Size Deleted Successfully!')
        } catch (error) {
            console.log('error deleting Price Size item', error);
            dispatch({ type: DELETE_PRICE_SIZE_FAILURE, payload: error });
            toast.warn('Price Size Might Be In Use')
        }
    }
}

export const createPriceSizeCategory = ({ requestData, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: CREATE_PRICE_SIZE_CATEGORY_REQUEST })
        try {
            const response = await api.post(`api/admin/pricessizes/pricesizecategory`, requestData,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                });
            dispatch({ type: CREATE_PRICE_SIZE_CATEGORY_SUCCESS, payload: response.data });
            console.log('successfully created price size category', response.data);
        } catch (error) {
            console.log('error creating price size category ', error);
            dispatch({ type: CREATE_PRICE_SIZE_CATEGORY_FAILURE, payload: error });

        }
    }
}

export const deletePriceSizeCategory = ({ priceSizeCategoryId, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: DELETE_PRICE_SIZE_CATEGORY_REQUEST });
        try {
            await api.delete(`/api/admin/pricessizes/pricesizecategory/${priceSizeCategoryId}`, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            })
            dispatch({ type: DELETE_PRICE_SIZE_CATEGORY_SUCCESS, payload: priceSizeCategoryId });
            console.log('successfully deleted price size Category', priceSizeCategoryId);
            toast.success('Price Size Category Deleted Successfully!')

        } catch (error) {
            console.log('error deleting price Size Category', error);
            dispatch({ type: DELETE_PRICE_SIZE_CATEGORY_FAILURE, payload: error });
            toast.warn('Price Size Category Might Be In Use')
        }
    }
}

export const getPriceSizeCategory = ({ restaurantId, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: GET_PRICE_SIZE_CATEGORY_REQUEST })
        try {
            const response = await api.get(`api/admin/pricessizes/restaurant/${restaurantId}/pricesizecategory`,
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                });
            dispatch({ type: GET_PRICE_SIZE_CATEGORY_SUCCESS, payload: response.data });
            console.log('successfully fetched price size category of restaurant', response.data);
        } catch (error) {
            console.log('error getting price size category ', error);
            dispatch({ type: GET_PRICE_SIZE_CATEGORY_FAILURE, payload: error });

        }
    }
}

export const updateStockOfPriceSize = (id, jwt) => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_PRICE_SIZE_STOCK_REQUEST })
        try {
            const response = await api.put(`api/admin/pricessizes/${id}/stock`, {},
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                });
            dispatch({ type: UPDATE_PRICE_SIZE_STOCK_SUCCESS, payload: response.data });
            console.log('successfully updated stock of price size of restaurant', response.data);
            toast.success('Price Size Stock Updated!')
        } catch (error) {
            console.log('error update stock price size ', error);
            dispatch({ type: UPDATE_PRICE_SIZE_STOCK_FAILURE, payload: error });
            toast.error('Error Updating Price Size Stock')
        }
    }
}