import { Card, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import React, { useState } from 'react'
import OrderTable from './OrderTable';

const orderStatus = [
  { label: "PENDING", value: "PENDING" },
  { label: "ACCEPTED", value: "ACCEPTED" },
  { label: "COMPLETED", value: "COMPLETED" },
  { label: "OUT FOR DELIVERY", value: "OUT_FOR_DELIVERY" },
  { label: "DELIVERED", value: "DELIVERED" },
  { label: "ALL", value: "ALL" },
]

const Orders = () => {

  const [filterValue, setFilterValue] = useState("ALL");

  const handleFilter = (event, value) => {
    setFilterValue(value)
  }
  return (
    <div className='px-2'>
      <Card className='p-5'>
        <Typography sx={{ paddingBottom: "1rem" }} variant='h5'>
          Order Status
        </Typography>
        <FormControl>
          <RadioGroup onChange={handleFilter} row name="orderStatus" value={filterValue || "ALL"}>
            {orderStatus.map((item) => <FormControlLabel
              key={item.label}
              value={item.value}
              control={<Radio />}
              label={item.label}
              sx={{ color: "gray" }}
            />)}
          </RadioGroup>
        </FormControl>
      </Card>
      <OrderTable orderStatus={filterValue} />
    </div>
  )
}

export default Orders