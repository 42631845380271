import { Avatar, Box, Button, Card, CardHeader, Chip, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create';
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { deleteMenuItem, getMenuItemByRestaurantId, updateMenuItemAvailability } from '../../../State/Menu/Action';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AreYouSureModal from '../../modal/AreYouSureModal';

const MenuTable = () => {
    const dispatch = useDispatch();
    const jwt = localStorage.getItem("jwt");
    const { menu } = useSelector(store => store);
    const navigate = useNavigate();
    const { restaurantId } = useParams();

    const requestData = {
        restaurantId: restaurantId
    };

    useEffect(() => {
        dispatch(getMenuItemByRestaurantId(requestData));
    }, []);

    const handleDeleteMenuItem = (foodId) => {
        dispatch(deleteMenuItem({ foodId, jwt }))
    }

    const handleMenuAvailability = (foodId) => {
        dispatch(updateMenuItemAvailability({ foodId, jwt }))
    }

    return (
        <Box>
            <Card className='mt-1'>
                <CardHeader action={
                    <IconButton onClick={() => navigate(`/admin/restaurants/${restaurantId}/add-menu`)} aria-label="settings"> <CreateIcon></CreateIcon></IconButton>}
                    title='Menu'
                    sx={{ pt: 2, alignItems: "center" }} />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Image</TableCell>
                                <TableCell align="left">Title</TableCell>
                                {/* <TableCell align="left">Ingredients</TableCell> */}
                                <TableCell align="left">Price & Size</TableCell>
                                <TableCell align="left">Availability</TableCell>
                                <TableCell align="left">Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {menu.menuItems.map((menuItem) => (
                                <TableRow key={menuItem.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="left"><Avatar sx={{ height: '90px', width: '90px' }} src={menuItem.images[0]}></Avatar></TableCell>
                                    <TableCell align="left">{menuItem.name}</TableCell>
                                    {/* <TableCell align="left">{menuItem.ingredients.map((ingredient) => <Chip key={ingredient.id} label={ingredient.name}></Chip>)}</TableCell> */}
                                    <TableCell align="left">{menuItem.pricesSizes.map((priceSize) => <Chip key={priceSize.id} label={priceSize.size + ' $' + priceSize.price}></Chip>)}</TableCell>
                                    <TableCell align="left">
                                        <Button
                                            onClick={() => handleMenuAvailability(menuItem.id)}
                                            size='large' variant='contained'
                                            className='py-[1rem] px-[2rem]'
                                            color={menuItem.available ? 'greenColor' : 'error'}>
                                            {menuItem.available ? "IN_STOCK" : "OUT_OF_STOCK"}
                                        </Button></TableCell>
                                    <TableCell align="left">
                                        <AreYouSureModal onAgreeClick={() => handleDeleteMenuItem(menuItem.id)} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
            <ToastContainer />
        </Box>
    )
}

export default MenuTable