// // import React from 'react'
// // import "slick-carousel/slick/slick.css";
// // import "slick-carousel/slick/slick-theme.css";
// // import Slider from 'react-slick';
// // import CarouselItem from './CarouselItem';
// // import {topMeals} from './topMeal'

// // export const MultiItemCarousel = ({slidesToShow}) => {
// //     const settings = {
// //         dots: true,
// //         infinite: true,
// //         speed: 500,
// //         slidesToShow: slidesToShow,
// //         slidesToScroll: 1,
// //         autoplay: true,
// //         autoplaySpeed: 2000,
// //         arrows: false
// //       };
// //   return (
// //     <div>
// //         <Slider {...settings}>
// //             {topMeals.map((item) => (<CarouselItem
// //              key={item.id}
// //              image={item.image}
// //              title={item.title}/>))}
// //         </Slider>
// //     </div>
// //   )
// // }

// // export default MultiItemCarousel;




import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import CarouselItem from './CarouselItem'; // Adjust this import to match your file structure
import { topMeals } from './topMeal.js'; // Assuming you're importing the data from somewhere

export const MultiItemCarousel = () => {
  const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(() => {
    const updateSlidesToShow = () => {
      if (window.innerWidth >= 1330) {
        setSlidesToShow(4);
      }else if (window.innerWidth >= 1024) {
        setSlidesToShow(3);
      }else if (window.innerWidth >= 768) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(1);
      }
    };

    updateSlidesToShow();

    window.addEventListener('resize', updateSlidesToShow);
    return () => window.removeEventListener('resize', updateSlidesToShow);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    centerMode: false,
    // centerPadding: '0px'
    
  };

  return (
    <div className=''>
      <div className='flex flex-col '>
        <Slider {...settings} className='pl-[5%] min-[360px]:pl-[10%] min-[420px]:pl-[13%] min-[500px]:pl-[20%] min-[600px]:pl-[25%] min-[768px]:pl-[10%] min-[1024px]:pl-[5%] '>
          {topMeals.map((item) => (
            <CarouselItem
              key={item.id}
              image={item.image}
              title={item.title}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default MultiItemCarousel;



