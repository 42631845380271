import { Avatar, AvatarGroup, Box, Card, CardHeader, Chip, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchPendingRestaurantOrders, fetchRestaurantOrders, updateOrderStatus } from '../../../State/RestaurantOrder/Action';
import sound from '../../../Storage/OrderReceived.mp3';
import { getAllRestaurants } from '../../../State/Restaurant/Action';
import { useParams } from 'react-router-dom';

const orderStatus = [
  { label: "PENDING", value: "PENDING" },
  { label: "ACCEPTED", value: "ACCEPTED" },
  { label: "COMPLETED", value: "COMPLETED" },
  { label: "OUT FOR DELIVERY", value: "OUT_FOR_DELIVERY" },
  { label: "DELIVERED", value: "DELIVERED" },
]

const OrderTable = (props) => {
  console.log('filter value is', props.orderStatus)
  const dispatch = useDispatch();
  const jwt = localStorage.getItem("jwt");
  const { restaurantId } = useParams();
  const { restaurantOrder } = useSelector(store => store);
  const [audio] = useState(new Audio(sound));

  useEffect(() => {
    dispatch(getAllRestaurants())
    dispatch(fetchRestaurantOrders({ restaurantId: restaurantId, orderStatus: props?.orderStatus, jwt }))
    console.log('orders of restaurant are', restaurantOrder);
  }, [props.orderStatus]);

  useEffect(() => {
    const intervalCall = setInterval(() => {
      dispatch(fetchPendingRestaurantOrders({ restaurantId: restaurantId, orderStatus: 'PENDING', jwt }));
      console.log('list of pending orders is', restaurantOrder?.ordersPending);
      if (restaurantOrder?.ordersPending?.length > 0) {
        console.log('pending orders more than 0', restaurantOrder.ordersPending)
        audio.play();
        dispatch(fetchRestaurantOrders({ restaurantId: restaurantId, orderStatus: props?.orderStatus, jwt }))
      }
    }, 10000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, [restaurantOrder?.ordersPending]);

  const handleUpdateOrderStatus = (orderId, orderStatus) => {
    audio.pause();
    dispatch(updateOrderStatus({ orderId, orderStatus, jwt }));
  }

  const styleOrderRow = (orderStatus) => {
    if (orderStatus === 'PENDING') return { backgroundColor: 'red' };
    else if (orderStatus === 'ACCEPTED') return { backgroundColor: 'green' };
    else if (orderStatus === 'COMPLETED') return { backgroundColor: 'blue' };
    else if (orderStatus === 'OUT_FOR_DELIVERY') return { backgroundColor: 'orange' };
    else if (orderStatus === 'DELIVERED') return { backgroundColor: 'purple' };
  }

  return (
    <Box>
      <Card className='mt-1'>
        <CardHeader
          title='All Orders'
          sx={{ pt: 2, alignItems: "center" }} />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align='left'>ID</TableCell>
                <TableCell align="left">Image</TableCell>
                <TableCell align="left">Customer</TableCell>
                <TableCell align="left">User Address</TableCell>
                <TableCell align="left">Self Pick Up</TableCell>
                <TableCell align="left">Price</TableCell>
                <TableCell align="left">Name</TableCell>
                {/* <TableCell align="left">Ingredients</TableCell> */}
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Update Order Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...restaurantOrder.orders].reverse().map((order, index) => (
                <TableRow
                  key={order.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {order.id}
                  </TableCell>
                  <TableCell align="left">
                    <AvatarGroup>
                      {order.items.map((orderItem) => <Avatar sx={{ height: '50px', width: '50px' }} key={orderItem.id} src={orderItem.food.images[0]} />)}
                    </AvatarGroup>
                  </TableCell>
                  <TableCell align="left">{order?.customer?.fullName}</TableCell>
                  <TableCell align="left">{order?.selfPickup === "YES" ? "" : order?.userAddress}</TableCell>
                  <TableCell align="left">{order?.selfPickup}</TableCell>
                  <TableCell align="left">{+Number(order?.totalPrice * 1.13).toFixed(2)}</TableCell>
                  <TableCell align="left">{order.items.map((orderItem) =>
                    <div key={orderItem.id}>
                      <p>{orderItem.quantity} x {orderItem.food?.name} - {orderItem?.size}</p>
                    </div>)}
                  </TableCell>
                  <TableCell align="left">{<Chip style={styleOrderRow(order.orderStatus)} label={order.orderStatus} />}</TableCell>
                  <TableCell align='left'>
                    {orderStatus.map((status) => <MenuItem key={status.label} value={order} onClick={() => handleUpdateOrderStatus(order.id, status.value)}>{status.label}</MenuItem>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Box>
  )
}

export default OrderTable