import { Delete } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import React from 'react'

const AreYouSureModal = ({ onAgreeClick }) => {
  const [openAlert, setOpenAlert] = React.useState(false);

  const handleClickOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleWhenAgreeClicked = () => {
    onAgreeClick();
    handleCloseAlert();
  }

  return (
    <div><IconButton color='primary' onClick={handleClickOpenAlert}><Delete />
    </IconButton>
      <Dialog
        open={openAlert}
        onClose={handleCloseAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to Delete Food Category?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlert}>Disagree</Button>
          <Button onClick={handleWhenAgreeClicked} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog></div>
  )
}

export default AreYouSureModal;