import { toast } from "react-toastify";
import { api } from "../../component/config/api";
import {
    ADD_ITEM_TO_CART_FAILURE, ADD_ITEM_TO_CART_REQUEST,
    ADD_ITEM_TO_CART_SUCCESS, CLEAR_CART_FAILURE, CLEAR_CART_REQUEST,
    CLEAR_CART_SUCCESS, FIND_CART_FAILURE, FIND_CART_REQUEST, FIND_CART_SUCCESS,
    GET_ALL_CART_ITEMS_FAILURE, GET_ALL_CART_ITEMS_REQUEST, GET_ALL_CART_ITEMS_SUCCESS,
    REMOVE_CART_ITEM_FAILURE, REMOVE_CART_ITEM_REQUEST, REMOVE_CART_ITEM_SUCCESS, UPDATE_CART_ITEM_FAILURE,
    UPDATE_CART_ITEM_REQUEST, UPDATE_CART_ITEM_SUCCESS
} from "./ActionTypes"

export const findCart = (token) => {
    return async (dispatch) => {
        dispatch({ type: FIND_CART_REQUEST });
        try {
            const response = await api.get(`/api/cart`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            dispatch({ type: FIND_CART_SUCCESS, payload: response.data });
            console.log('cart fetched with data:', response.data)
        } catch (error) {
            console.log('error finding cart', error);
            dispatch({ type: FIND_CART_FAILURE, payload: error });
        }
    }
}

export const getAllCartItems = ({ requestData }) => {
    return async (dispatch) => {
        dispatch({ type: GET_ALL_CART_ITEMS_REQUEST });
        try {
            const response = await api.get(`/api/carts/${requestData.cartId}/items`, {
                headers: {
                    Authorization: `Bearer ${requestData.token}`,
                },
            });
            dispatch({ type: GET_ALL_CART_ITEMS_SUCCESS, payload: response.data });
        } catch (error) {
            console.log('error getting all cart items', error);
            dispatch({ type: GET_ALL_CART_ITEMS_FAILURE, payload: error });
        }
    }
}

export const clearCart = () => {
    return async (dispatch) => {
        dispatch({ type: CLEAR_CART_REQUEST });
        try {
            const response = await api.put(`/api/cart/clear`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
            });
            dispatch({ type: CLEAR_CART_SUCCESS, payload: response.data });
            toast.success('Cart Cleard !')
        } catch (error) {
            console.log('error clearing the cart', error);
            dispatch({ type: CLEAR_CART_FAILURE, payload: error });
            toast.error('Clearing Cart Failed')
        }
    }
}

export const addItemToCart = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: ADD_ITEM_TO_CART_REQUEST });
        try {
            const response = await api.put(`/api/cart/add-item`, requestData.cartItem, {
                headers: {
                    Authorization: `Bearer ${requestData.jwt}`,
                },
            });
            dispatch({ type: ADD_ITEM_TO_CART_SUCCESS, payload: response.data });
            toast.success('Item Added To Cart !')
        } catch (error) {
            console.log('error adding item to cart', error);
            dispatch({ type: ADD_ITEM_TO_CART_FAILURE, payload: error });
            toast.error('Adding Item To Cart Failed')
        }
    }
}

export const updateCartItem = ({ data, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_CART_ITEM_REQUEST });
        try {
            const response = await api.put(`/api/cart/cart-item/update`, data, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            });
            dispatch({ type: UPDATE_CART_ITEM_SUCCESS, payload: response.data });
            console.log('cart updated successfully', response.data);
            toast.success('Cart Item Updated !')

        } catch (error) {
            console.log('error updating item to cart', error);
            dispatch({ type: UPDATE_CART_ITEM_FAILURE, payload: error });
            toast.error('Update Cart Item Failed')
        }
    }
}

export const removeCartItem = ({ cartItemId, jwt }) => {
    return async (dispatch) => {
        dispatch({ type: REMOVE_CART_ITEM_REQUEST });
        try {
            const response = await api.delete(`/api/cart/cart-item/${cartItemId}/remove`, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            });
            dispatch({ type: REMOVE_CART_ITEM_SUCCESS, payload: response.data });
            toast.success('Item Removed From Cart !')
        } catch (error) {
            console.log('error removing item from cart', error);
            dispatch({ type: REMOVE_CART_ITEM_FAILURE, payload: error });
            toast.error('Removing Item From Cart Failed')
        }
    }
}