import { Box, Button, Card, CardHeader, IconButton, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AreYouSureModal from '../../modal/AreYouSureModal';
import { deletePriceSize, getPricesSizesOfRestaurant, updateStockOfPriceSize } from '../../../State/pricesSizes/Action';
import CreatePriceSizeForm from './CreatePriceSizeForm';
import { useParams } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const PricesSizesTable = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { pricesSizes } = useSelector(store => store);
  const jwt = localStorage.getItem("jwt");
  const { restaurantId } = useParams();

  useEffect(() => {
    dispatch(getPricesSizesOfRestaurant({ restaurantId: restaurantId, jwt }));
    console.log('Prices Sizes of restaurant are', pricesSizes);
  }, []);

  const handleUpdateStock = (priceSizeId) => {
    dispatch(updateStockOfPriceSize(priceSizeId, jwt))
  }

  const handleDeletePriceSize = (priceSizeId) => {
    dispatch(deletePriceSize({ priceSizeId, jwt }))
  }

  return (
    <Box>
      <Card className='mt-1'>
        <CardHeader action={
          <IconButton onClick={handleOpen} aria-label="settings"> <CreateIcon></CreateIcon></IconButton>}
          title='Prices & Sizes'
          sx={{ pt: 2, alignItems: "center" }} />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">ID</TableCell>
                <TableCell align="left">Size</TableCell>
                <TableCell align="left">Price</TableCell>
                <TableCell align="left">Size Category</TableCell>
                <TableCell align="left">Availability</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pricesSizes.pricesSizes.map((priceSize) => (
                <TableRow key={priceSize.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="left">{priceSize.id}</TableCell>
                  <TableCell align="left">{priceSize.size}</TableCell>
                  <TableCell align="left">{priceSize.price}</TableCell>
                  <TableCell align="left">{priceSize.priceSizeCategory.priceSizeCategoryName}</TableCell>
                  <TableCell align="left"><Button size='large' variant='contained'
                    color={priceSize.inStock ? 'greenColor' : 'error'}
                    onClick={() => handleUpdateStock(priceSize.id)}>{priceSize.inStock ? "IN_STOCK" : "OUT_OF_STOCK"}
                  </Button>
                  </TableCell>
                  <TableCell align="right"><AreYouSureModal onAgreeClick={() => handleDeletePriceSize(priceSize.id)} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CreatePriceSizeForm />
        </Box>
      </Modal>
    </Box>
  )
}

export default PricesSizesTable