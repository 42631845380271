export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE';

export const GET_USER_ORDERS_REQUEST = 'GET_USER_ORDER_REQUEST';
export const GET_USER_ORDERS_SUCCESS = 'GET_USER_ORDER_SUCCESS';
export const GET_USER_ORDERS_FAILURE = 'GET_USER_ORDER_FAILURE';

export const GET_USER_NOTIFICATION_REQUEST = 'GET_USER_NOTIFICATION_REQUEST';
export const GET_USER_NOTIFICATION_SUCCESS = 'GET_USER_NOTIFICATION_SUCCESS';
export const GET_USER_NOTIFICATION_FAILURE = 'GET_USER_NOTIFICATION_FAILURE';