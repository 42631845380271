
import Line from "../../Storage/line.png";

const MenuItem = (props) => {

    let {image, mainHeading, mainHeadingDesc, data, index} = {...props}
    index += 1

  return (
    <div className= {`${(index % 2 === 0) ? "bg-gradient-to-br from-yellow-50 to-red-50" : "bg-[#ffffff]"}  flex justify-center items-center  overflow-hidden py-[50px]`}>
        <div className=" pb-[20px]  flex flex-col justify-center items-center text-black">
            <div className=" w-[330px] sm:w-[550px] md:w-[720px] lg:w-[1000px] xl:w-[1200px] flex flex-col-reverse lg:flex-row-reverse lg:justify-around items-center mt-[40px] ">
                <div className=" w-[320px] sm:w-[450px] md:w-[650px] lg:w-[500px] px-[30px] py-[10px] " >
                    

                    {data.map((categories, index) => (
                        <div className="description-div py-[15px]" key={index}>
                            <div className="flex  justify-between pt-[20px] pb-[7px] text-secondary mb-[10px]">
                                <p className="text-[19px]  md:text-[21px] font-semibold font-logo-font">{categories.heading}</p>
                                <p className="text-[19px] md:text-[21px] font-logo-font">{categories.price}</p>
                            </div>
                                <p className="font-description-font text-[15px] text-justify">{categories.description}</p>

                        </div>
                        ))}
                            

                </div>
                <div className=" w-[300px] sm:w-[400px] md:w-[480px] lg:w-[370px] flex flex-col justify-center items-center">
                    <div className=""> 
                        <h1 className="font-logo-font text-primary font-semibold text-[22px] xl:text-[32px]  md:text-3xl mb-[10px] text-center"> {mainHeading}</h1>
                        <p className="text-center font-description-font text-[13px] xl:text-[18px]">{mainHeadingDesc}</p>
                    </div>
                    <div className="w-[280px] sm:w-[400px] md:w-[480px] lg:w-[370px] mt-[20px]">
                                
                        <img src={image} alt="" className="w-[280px] sm:w-[400px] md:w-[480px] lg:w-[370px]" />
                                
                    </div>
                </div>
                        
            </div>

            <img src={Line} alt="" className="h-[15px] w-[280px] sm:w-[350px] md:w-[450px] xl:w-[650px] mt-[20px]" />
                    
        </div>
    </div>
  )
}

export default MenuItem



