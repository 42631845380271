import React from 'react'

export const CarouselItem = ({image, title}) => {
  return (

    <div className='pb-[20px]  bg-yellow-50 h-[270px] w-[260px]  text-black shadow-lg  mb-[50px] flex flex-col justify-center items-center '>
      <div className='bg-white'>
          <img src={image} alt="" className='w-[260px] h-[230px] rounded-t-[30px]'/>
      </div>
      <p className='text-2xl text-black font-logo-font tracking-widest text-center pt-[10px]  border-2   border-tertiary rounded-b-[14px] bg-yellow-50'>{title}</p>
    </div>

  )
}

export default CarouselItem;

