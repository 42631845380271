export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const SAVE_PASSWORD_REQUEST = 'SAVE_PASSWORD_REQUEST';
export const SAVE_PASSWORD_SUCCESS = 'SAVE_PASSWORD_SUCCESS';
export const SAVE_PASSWORD_FAILURE = 'SAVE_PASSWORD_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const ADD_TO_FAVORITE_REQUEST = 'ADD_TO_FAVORITE_REQUEST';
export const ADD_TO_FAVORITE_SUCCESS = 'ADD_TO_FAVORITE_SUCCESS';
export const ADD_TO_FAVORITE_FAILURE = 'ADD_TO_FAVORITE_FAILURE';

export const LOGOUT = 'LOGOUT';