import React from 'react'
import ProfileNavigation from './ProfileNavigation'
import { Route, Routes } from 'react-router-dom';
import UserProfile from './UserProfile';
import Orders from './Orders';
import Address from './Address';
import Favorites from './Favorites';
import Events from './Events';
import { Navbar } from '../Navbar/Navbar';

const Profile = () => {
  return (
    <>
    <Navbar/>
    <div className='lg:flex justify-between'>
      <div>
        <ProfileNavigation />
      </div>
      <div className='lg:w-[100%] justify-between'>
        <Routes>
          <Route path='/' element={<UserProfile />} />
          <Route path='/orders' element={<Orders />} />
          <Route path='/address' element={<Address />} />
          <Route path='/favorites' element={<Favorites />} />
          <Route path='/events' element={<Events />} />
        </Routes>
      </div>

    </div>
    </>
  )
}

export default Profile