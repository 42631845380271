import React from 'react'
import IngredientsTable from './IngredientsTable'
import { Grid } from '@mui/material'
import IngredientsCategoryTable from './IngredientsCategoryTable'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

const Ingredients = () => {
  return (
    <div className='px-2'>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={7}>
          <IngredientsTable></IngredientsTable>
        </Grid>
        <Grid item xs={12} lg={5}>
          <IngredientsCategoryTable></IngredientsCategoryTable>
        </Grid>
      </Grid>
      <ToastContainer />
    </div>
  )
}

export default Ingredients