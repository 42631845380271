import { Avatar, Card, Divider } from '@mui/material'
import React from 'react'

const OrderCard = ({ item, order }) => {
  const orderDateTime = new Date(order.createdAt)
  return (
    <Card className='lg:flex justify-between items-center p-5'>
      <div className='lg:flex items-center lg:space-x-5'>
        <div>
          <p className='mb-5'>Order Creation Date : {orderDateTime.toDateString()}</p>
          {order.items.map((orderItem) =>
            <div key={orderItem.id} className='flex items-center pb-3 pr-2'>
              <Avatar sx={{ height: '90px', width: '90px' }} src={orderItem.food.images[0]} />
              <p className='ml-5'>{orderItem.quantity} x {orderItem.food.name}</p>
              <Divider />
            </div>)
          }
        </div>
      </div>
      <div>
        {order.orderStatus}
      </div>
    </Card>
  )
}
export default OrderCard