import { applyMiddleware, combineReducers } from "redux";
import { authReducer } from "./Authentication/Reducer";
import { thunk } from "redux-thunk";
import restaurantReducer from "./Restaurant/Reducer";
import menuItemReducer from "./Menu/Reducer";
import cartReducer from "./Cart/Reducer";
import { orderReducer } from "./Order/Reducer";
import restaurantOrderReducer from "./RestaurantOrder/Reducer";
import { ingredientReducer } from "./Ingredients/Reducer";
import { compose } from 'redux';
import { configureStore } from '@reduxjs/toolkit'
import { priceSizeReducer } from "./pricesSizes/Reducer";
import guestCartReducer from "./GuestCart/Reducer";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducer = combineReducers({
    auth: authReducer,
    restaurant: restaurantReducer,
    menu: menuItemReducer,
    cart: cartReducer,
    guestCart: guestCartReducer,
    order: orderReducer,
    restaurantOrder: restaurantOrderReducer,
    ingredients: ingredientReducer,
    pricesSizes: priceSizeReducer,
})

export const store = configureStore({ reducer }, composeEnhancer(applyMiddleware(thunk)));