import platter1 from '../Storage/platter1.png'
import pizza from '../Storage/pizza.png'
import drink from '../Storage/drink.png'


export const menuItems = [
    {
        'image' : platter1,
        'mainHeading' : 'Savor Pulao Platter',
        'mainHeadingDesc' : 'Try our spicy degi pulao with Shami Kabab and Roast Chicken ',
        'data' : [
            {
                'heading' : "Savor Pulao for 1",
                'price' : '17.99$',
                'description' : 'Savor Pulao Rice, 2 Chicken Shami Kabab, Half Chicken Roast (For a short time only!), Garlic Sauce, Green Chatni'
            },
            {
                'heading' : "Savor Pulao for 2",
                'price' : '34.99$',
                'description' : 'Rice Platter, 4 Chicken Shami Kabab, 1 Whole Chicken Roast (For a Short Time Only!), Garlic Sauce, Green Chatni'
            },
            {
                'heading' : "Savor Pulao for 4",
                'price' : '69.99$',
                'description' : 'Rice, 8 Chicken Shami Kebab, 2 Whole Chicken(Short Time Only!), Garlic Sauce, Green Chatni'
            },
            {
                'heading' : "Shami Kabab & Chatni",
                'price' : '7.99$',
                'description' : 'Delicious Chicken Shami Kabab, served with our signature Green and Red Chatnis, price for 2'
            }
        ]
    },
    {
        'image' : pizza,
        'mainHeading' : 'Signature Pizzas',
        'mainHeadingDesc' : 'All prices are for Regular Size, Large add $5, X-Large add $8',
        'data' : [
            {
                'heading' : "Tehzeeb Tandoori Tikka",
                'price' : '21.99',
                'description' : 'Spicy Chicken in our white sauce with a smoky touch'
            },
            {
                'heading' : "Qalandri Steak Pizza",
                'price' : '21.99',
                'description' : 'Halal Pizza featuring our Signature Mushroom Creamy Sauce with Steak Chunks, Ground Beef and Pepperoni'
            },
            {
                'heading' : "Malai Boti",
                'price' : '21.99',
                'description' : 'Malai Sauce with generous Creamy Chicken Chunks, one of our top selling Pizza'
            },
            {
                'heading' : "Pepperoni",
                'price' : '15.99',
                'description' : 'Fresh Pepperoni with a delicious Alfredo base'
            },
            {
                'heading' : "Cheese Pizza",
                'price' : '14.99',
                'description' : 'Simple Cheese Pizza made to Perfection'
            }
        ]
    },
    {
        'image' : drink,
        'mainHeading' : 'Refreshing Drinks',
        'mainHeadingDesc' : "Cool off with our delicious and refreshing drink options, perfect for any time of day",
        'data' : [
            {
                'heading' : "Fresh Lemon Masala Soda",
                'price' : '6.99',
                'description' : 'Soda with a minty masala taste, good summer refresher'
            },
            {
                'heading' : "Pop",
                'price' : '1.99',
                'description' : 'Any of Coke, Sprite, Canada Dry'
            },
            {
                'heading' : "Ayran Yogurt Drink",
                'price' : '2.99',
                'description' : 'Creamy yogurt Lassi drink'
            }
        ]
    }
]