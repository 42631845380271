import AboutImage from "../../Storage/about.png";
import Line from "../../Storage/line.png";
import "./AboutUs.css";

export default function About(){
    return(
        <div className=" bg-gradient-to-br from-orange-50 via-yellow-50 to-red-50  flex justify-center pt-[50px] lg:pt-[130px] pb-[50px]">
            <div className=" text-center flex-col jsutify-center items-center">
                <h1 className="font-logo-font text-primary font-semibold text-3xl sm:text-4xl md:text-5xl mb-[10px]">About Us</h1>
                <div className="flex justify-center">
                    <img src={Line} alt="line" className="w-[250px] h-[10px] -translate-y-1 sm:w-[350px] sm:h-[20px]"/>
                </div>

                <div className="flex flex-col  sm:flex-row sm:justify-between sm:gap=[50px] items-center mt-[100px] xl:gap-10">


                    <div className=" animate-spin-slow lg:mr-[50px] flex justify-center"> 
                        <img src={AboutImage} className="w-[300px]  lg:w-[570px]  " alt="Biryani" />
                        {/* <img src={GoldLeaf} className="hidden sm:block h-[60px] sm:h-[150px] sm:translate-x-[60px] sm:translate-y-[50px] 2xl:h-[200px] translate-x-[80px] -translate-y-[280px] rotate-[130deg]" alt="" /> */}
                    </div>


                    <div className="w-[300px] lg:w-[450px] sm:translate-y-[0px] font-logo-font text-black font-medium text-[18px] items-center align-middle text-center pt-[30px] flex sm:text-[18px] lg:text-[25px]">
                        <p>At Halal Yum, we offer a <span className="text-[#9e8527]">24K</span> food experience with authentic halal dishes crafted from high-quality ingredients.</p>
                        {/* <img src={GoldLeaf} className="hidden sm:block  h-[60px]  -translate-x-[60px] translate-y-[80px] sm:-translate-y-[300px] rotate-[-40deg] sm:h-[150px] 2xl:h-[200px]" alt="" /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}