import { Dashboard, ShoppingBag } from '@mui/icons-material'
import ShopTwoIcon from '@mui/icons-material/ShopTwo';
import CategoryIcon from '@mui/icons-material/Category';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react'
import { AppBar, Box, CssBaseline, Divider, Drawer, IconButton, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../State/Authentication/Action';

const menu = [
  { title: "Dashboard", icon: <Dashboard />, path: "/" },
  { title: "Orders", icon: <ShoppingBag />, path: "/orders" },
  { title: "Menu", icon: <ShopTwoIcon />, path: "/menu" },
  { title: "Food Category", icon: <CategoryIcon />, path: "/foodcategory" },
  { title: "Ingredients", icon: <FastfoodIcon />, path: "/ingredients" },
  { title: "Prices & Sizes", icon: <PriceChangeIcon />, path: "/pricessizes" },
  // { title : "Events", icon : <EventIcon/>, path: "/events" },
  { title: "Restaurant Details", icon: <AdminPanelSettingsIcon />, path: "/restaurantdetails" },
  { title: "Logout", icon: <LogoutIcon />, path: "/logout" },
]

const AdminSideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const drawerWidth = 240;
  const { restaurant } = useSelector(store => store);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleNavigate = (item) => {
    navigate(`/admin/restaurants/${restaurant?.restaurants[0]?.id}${item.path}`)
    if (item.title === 'Logout') {
      navigate("/");
      dispatch(logout());
    }
  }
  return (
    <div className=' z-0'>
        
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
              position="relative"
              sx={{
                width: { sm: `0px` },
                ml: { sm: `0px` },
              }}
            >
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: 'none' } }}
                >
                  <MenuIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Box
              component="nav"
              sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
              aria-label="mailbox folders"
            >
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Drawer
              //   container={container}
                variant="temporary"
                open={mobileOpen}
                onTransitionEnd={handleDrawerTransitionEnd}
                onClose={handleDrawerClose}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: 'block', sm: 'none' },
                  '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
              >
                  {<div className='w-[70vw] lg:w-[20vw] pt-20 flex flex-col justify-center text-xl space-y-[1.65rem]'>
                      {menu.map((item, i) =>  <React.Fragment key={item.title}>
                      <div onClick={() => handleNavigate(item)} className='px-5 flex items-center gap-5 cursor-pointer'>
                          {item.icon}
                          <span>{item.title}</span>
                      </div>
                      { i !== menu.length-1 && <Divider/>}
                      </React.Fragment>)}
                  </div>}
                </Drawer>
                <Drawer
                  variant="permanent"
                  sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                  }}
                  open
                >
                  {<div className='w-[70vw] lg:w-[20vw] pt-24 flex flex-col justify-center text-xl space-y-[1.2rem]'>
                        {menu.map((item, i) =>  <React.Fragment key={item.title}>
                        <div onClick={() => handleNavigate(item)} className='px-5 flex items-center gap-5 cursor-pointer'>
                            {item.icon}
                            <span>{item.title}</span>
                        </div>
                        { i !== menu.length-1 && <Divider/>}
                        </React.Fragment>)}
                  </div>}
                </Drawer>
            </Box>
          </Box>
    </div>
  )
}

export default AdminSideBar


