import { CREATE_PRICE_SIZE_CATEGORY_SUCCESS, CREATE_PRICE_SIZE_SUCCESS, DELETE_PRICE_SIZE_CATEGORY_FAILURE, DELETE_PRICE_SIZE_CATEGORY_REQUEST, DELETE_PRICE_SIZE_CATEGORY_SUCCESS, DELETE_PRICE_SIZE_FAILURE, DELETE_PRICE_SIZE_REQUEST, DELETE_PRICE_SIZE_SUCCESS, GET_PRICES_SIZES_REQUEST, GET_PRICES_SIZES_SUCCESS, GET_PRICE_SIZE_CATEGORY_REQUEST, GET_PRICE_SIZE_CATEGORY_SUCCESS, UPDATE_PRICE_SIZE_STOCK_REQUEST, UPDATE_PRICE_SIZE_STOCK_SUCCESS } from "./ActionTypes"

const initialState = {
    pricesSizes: [],
    update: null,
    priceSizeCategory: [],
    loading: false,
    error: null
}

export const priceSizeReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRICES_SIZES_REQUEST:
        case GET_PRICE_SIZE_CATEGORY_REQUEST:
        case UPDATE_PRICE_SIZE_STOCK_REQUEST:
        case DELETE_PRICE_SIZE_REQUEST:
        case DELETE_PRICE_SIZE_CATEGORY_REQUEST:
            return {
                ...state, loading: true, error: null
            }
        case GET_PRICES_SIZES_SUCCESS:
            return {
                ...state, pricesSizes: action.payload, loading: false
            }
        case GET_PRICE_SIZE_CATEGORY_SUCCESS:
            return {
                ...state, priceSizeCategory: action.payload, loading: false
            }
        case CREATE_PRICE_SIZE_CATEGORY_SUCCESS:
            return {
                ...state, priceSizeCategory: [...state.priceSizeCategory, action.payload], loading: false
            }
        case DELETE_PRICE_SIZE_SUCCESS:
            return {
                ...state, pricesSizes: state.pricesSizes.filter((priceSize) => priceSize.id !== action.payload)
            }
        case DELETE_PRICE_SIZE_CATEGORY_SUCCESS:
            return {
                ...state, priceSizeCategory: state.priceSizeCategory.filter((priceSizeCategory) => priceSizeCategory.id !== action.payload)
            }
        case CREATE_PRICE_SIZE_SUCCESS:
            return {
                ...state, pricesSizes: [...state.pricesSizes, action.payload], loading: false
            }
        case UPDATE_PRICE_SIZE_STOCK_SUCCESS:
            return {
                ...state, update: action.payload, pricesSizes: state.pricesSizes.map((item) => {
                    return item.id === action.payload.id ? action.payload : item
                })
            }
        case DELETE_PRICE_SIZE_FAILURE:
        case DELETE_PRICE_SIZE_CATEGORY_FAILURE:
            return {
                ...state, loading: false, error: action.payload
            }
        default:
            return state;

    }
}