import { Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createPriceSizeCategory } from '../../../State/pricesSizes/Action';

const CreatePriceSizeCategoryForm = () => {
    const { restaurant } = useSelector(store => store);
    const dispatch = useDispatch();
    const jwt = localStorage.getItem("jwt");
    const [formData, setFormData] = useState({ priceSizeCategoryName: "" });
    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            priceSizeCategoryName: formData.priceSizeCategoryName,
            restaurantId: restaurant?.restaurants[0]?.id
        }
        dispatch(createPriceSizeCategory({ requestData: data, jwt }))
        console.log(data)

    }
    const handleInputChange = (event) => {
        const { name, value } = event.target
        setFormData({
            ...formData, [name]: value
        })
    }
    return (
        <div className=''>
            <div className='p-5'>
                <h1 className='text-gray-400 text-center text-xl pb-10'>Create Price Size Category</h1>
                <form className='space-y-4' onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        id='priceSizeCategoryName'
                        name='priceSizeCategoryName'
                        label='Price Size Category Name'
                        variant='outlined'
                        onChange={handleInputChange}
                        value={formData.priceSizeCategoryName}>
                    </TextField>
                    <Button variant='contained' type='submit'>Create Price Size Category</Button>
                </form>
            </div>
        </div>
    )
}

export default CreatePriceSizeCategoryForm