import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../component/Home/Home'
import RestaurantDetails from '../component/Restaurant/RestaurantDetails'
import Cart from '../component/Cart/Cart'
import Profile from '../component/Profile/Profile'
import { Auth } from '../component/Auth/Auth'
import PaymentSuccess from '../component/PaymentSuccess/PaymentSuccess'
import Footer from '../component/Footer/Footer'
import VisitUs from '../component/Visit Us/VisitUs'
import About from '../component/About Us/AboutUs'
import FlashOrder from '../component/Flash Order/FlashOrder'
import {
} from "react-stomp-hooks";
import SavePassword from '../component/Auth/SavePassword'
import Menu from '../component/Menu/Menu'
import { Navbar } from '../component/Navbar/Navbar'

const CustomerRouter = () => {
  return (
    <div className=''>
        
        <Routes>
            <Route path='/' element={<Home/>}></Route>
            <Route path='/account/:register' element={<Home/>}></Route>
            <Route path='/restaurant/:city/:title/:restaurantId' element={<RestaurantDetails/>}></Route>
            <Route path='/cart' element={<Cart/>}></Route>
            <Route path='/my-profile/*' element={<Profile/>}></Route>
            <Route path='/payment/success' element={<PaymentSuccess/>}></Route>
            <Route path='/auth/savepassword/:token' element={<SavePassword/>}></Route>
        </Routes>
        
        
        
        {/* <FlashOrder/>
        <About/>
        <Menu/>
        <Auth/>
        <VisitUs/>
        {/* <Footer/> */}

    </div>
  )
}

export default CustomerRouter