import { useState } from "react";
import PlacesAutocomplete from 'react-places-autocomplete';
import "./MyPlaceAutoComplete.css"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { getDistance } from 'geolib';


export const MyPlaceAutoComplete = () => {
  const [distance, setDistance] = useState(null);
  const [address, setAddress] = useState("")

  const handleChange = address => {
    setAddress(address);
  };

  const handleSelect = async (address) => {
    setAddress(address);
    localStorage.setItem("user_address", address);
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        calculateDistance(lat, lng)
      });
  }

  const calculateDistance = async (lat, lng) => {
    const distance = getDistance({ latitude: 43.582061767578125, longitude: -79.61913299560547 }, { latitude: lat, longitude: lng })
    let distanceinKM = 0
    if (distance) {
      distanceinKM = distance / 1000;
      distanceinKM = distanceinKM?.toFixed(1);
      if (distanceinKM < 10) {
        localStorage.setItem("eligibleForDelivery", true)
      } else {
        localStorage.setItem("eligibleForDelivery", false)
      }
      setDistance(distanceinKM);
    }
    console.log('distance in km is equal to =====', distanceinKM)
  };

        return (
            <div className="flex flex-col justify-center items-center">
              <PlacesAutocomplete
                value={address}
                onChange={handleChange}
                onSelect={handleSelect}
              >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                      <input 
                        {...getInputProps({
                          placeholder: ' Enter your Address',
                          className: 'px-[20px] py-[15px] rounded-md  font-sans bg-[#FFF] text-center border-2 border-[#9D8C62] text-[#9D8C62]',
                          
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: 'white', cursor: 'pointer' }
                            : { backgroundColor: 'white', cursor: 'pointer' };
                          return (
                            <div key={suggestion.description} className="input-suggestions"
                              {...getSuggestionItemProps(suggestion, {
                                style,
                              })}
                            >
                              <LocationOnIcon/> <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>

      {/* When the Order Distance is more then 15km */}
      <div className='text-center text-red-500 mt-5'>{distance && distance > 15 && "We Deliver with in 15 KM Radius at the moment !"}</div>
    </div>
  );

}

export default MyPlaceAutoComplete;