export const GET_PRICES_SIZES_REQUEST = 'GET_PRICES_SIZES_REQUEST';
export const GET_PRICES_SIZES_SUCCESS = 'GET_PRICES_SIZES_SUCCESS';
export const GET_PRICES_SIZES_FAILURE = 'GET_PRICES_SIZES_FAILURE';

export const UPDATE_PRICE_SIZE_STOCK_REQUEST = 'UPDATE_PRICE_SIZE_STOCK_REQUEST';
export const UPDATE_PRICE_SIZE_STOCK_SUCCESS = 'UPDATE_PRICE_SIZE_STOCK_SUCCESS';
export const UPDATE_PRICE_SIZE_STOCK_FAILURE = 'UPDATE_PRICE_SIZE_STOCK_FAILURE';

export const CREATE_PRICE_SIZE_REQUEST = 'CREATE_PRICE_SIZE_REQUEST';
export const CREATE_PRICE_SIZE_SUCCESS = 'CREATE_PRICE_SIZE_SUCCESS';
export const CREATE_PRICE_SIZE_FAILURE = 'CREATE_PRICE_SIZE_FAILURE';

export const DELETE_PRICE_SIZE_REQUEST = 'DELETE_PRICE_SIZE_REQUEST';
export const DELETE_PRICE_SIZE_SUCCESS = 'DELETE_PRICE_SIZE_SUCCESS';
export const DELETE_PRICE_SIZE_FAILURE = 'DELETE_PRICE_SIZE_FAILURE';


export const CREATE_PRICE_SIZE_CATEGORY_REQUEST = 'CREATE_PRICE_SIZE_CATEGORY_REQUEST';
export const CREATE_PRICE_SIZE_CATEGORY_SUCCESS = 'CREATE_PRICE_SIZE_CATEGORY_SUCCESS';
export const CREATE_PRICE_SIZE_CATEGORY_FAILURE = 'CREATE_PRICE_SIZE_CATEGORY_FAILURE';

export const DELETE_PRICE_SIZE_CATEGORY_REQUEST = 'DELETE_PRICE_SIZE_CATEGORY_REQUEST';
export const DELETE_PRICE_SIZE_CATEGORY_SUCCESS = 'DELETE_PRICE_SIZE_CATEGORY_SUCCESS';
export const DELETE_PRICE_SIZE_CATEGORY_FAILURE = 'DELETE_PRICE_SIZE_CATEGORY_FAILURE';


export const GET_PRICE_SIZE_CATEGORY_REQUEST = 'GET_PRICE_SIZE_CATEGORY_REQUEST';
export const GET_PRICE_SIZE_CATEGORY_SUCCESS = 'GET_PRICE_SIZE_CATEGORY_SUCCESS';
export const GET_PRICE_SIZE_CATEGORY_FAILURE = 'GET_PRICE_SIZE_CATEGORY_FAILURE';