import { CssBaseline, ThemeProvider } from '@mui/material';
import './App.css';
import { darkTheme, theme } from './Theme/DarkTheme';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from './State/Authentication/Action';
import { findCart } from './State/Cart/Action';
import Routers from './Router/Routers';
import { getRestaurantByUserId } from './State/Restaurant/Action';

function App() {
  const dispatch = useDispatch();
  const jwt = localStorage.getItem("jwt");
  const {auth} = useSelector(store => store);
  useEffect(() => {
    if(auth.jwt || jwt) {
      dispatch(getUser(auth.jwt || jwt));
      dispatch(findCart(auth.jwt || jwt))
    }
  }, [auth.jwt]);

  useEffect(() => {
    if(auth?.user?.role === 'ROLE_RESTAURANT_OWNER') {
      dispatch(getRestaurantByUserId(auth.jwt || jwt))
    }
  }, [auth.jwt])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Routers/>
    </ThemeProvider>
  );
}

export default App;
